@import "./functions";
@import "./media";
@mixin defaultWrapper {
  width: 1500px;
  @include _1600 {
    width: 1290px;
    @include _1320 {
      width: 100%;
    }
  }
}