@import "./base/scroll";


.filter-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 70px 0;
  background: rgba(0, 84, 166, 0.8);
  @include _1170 {
    padding: 60px 30px;
    @include _600 {
      padding: 40px 15px;
    }
  }
  &-wrapper {
    padding: 38px 40px 38px 34px;
    //padding: calc_fluid(20, 38,320,1920) calc_fluid(24,40,320,1920) calc_fluid(30,48,320,1920) calc_fluid(14,34,320,1920);
    @include _600 {
      padding: 20px 24px 30px 14px;
    }
    margin-top: 40px;
    background: #fff;
    border-radius: 3px;
    width: 1470px;
    display: flex;
    flex-flow: row wrap;
    @include _1600 {
      width: 1290px;
      justify-content: space-between;
      @include _1320 {
        width: 1140px;
        @include _1170 {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  }
  &-item {
    height: 53px;
    //height: calc_fluid(40,53,320,1920);
    @include _600 {
      height: 40px;
    }
    background: #fff;
    position: relative;
    border: 1px solid #878787;
    user-select: none;
    $fbi: &;
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin: 10px 0 0 10px;
    cursor: pointer;
    &-arrow {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 58px;
      @include _600 {
        width: 40px;
      }
      //width: calc_fluid(40,58,320,1920);
      display: flex;
      align-items: center;
      justify-content: center;
      &__icon {
        transition: transform .3s, fill .3s;
        width: 20px;
        height: 20px;
        @include _600 {
          width: 16px;
          height: 16px;
        }
        //width: calc_fluid(16,20,320,1920);
        //height: calc_fluid(16,20,320,1920);
        fill: $blk;
      }
    }
    &-children {
      position: absolute;
      user-select: none;
      max-height: 300px;
      //top: calc(100% - 1px);
      //left: -1px;
      //width: calc(100% + 2px);
      top: 100%;
      width: 100%;
      background: #fff;
      border: 1px solid #CDCDCD;
      display: flex;
      opacity: 0;
      z-index: -1;
      padding: 13px 13px 13px 27px;
      cursor: default;
      &.default {
        display: block;
      }
      &.scroll_h {
        height: 300px;
      }
      &__item {
        padding: 5px 0;
        font-size: 16px;
        line-height: 24px;
        color: $blk;
        cursor: pointer;
        user-select: none;
        transition: color .3s;
        &:hover {
          color: $red;
        }
      }
    }
    &__text {
      font-size: 16px;
      line-height: 24px;
      @include _600 {
        font-size: 14px;
        line-height: 22px;
      }
      //font-size: calc_fluid(14,16,320,1920);
      //line-height: calc_fluid(22,24,320,1920);
      user-select: none;
      padding-left: 20px;
    }
    &.glass {
      width: 260px;
    }
    &.mark {
      width: 195px;
    }
    &.model {
      width: 189px;
    }
    &.year {
      width: 177px;
      #{$fbi} {
        &-arrow {
          width: 48px;
          border-left: 1px solid #878787;
          &__icon {
            width: 24px;
            height: 24px;
            @include _600 {
              width: 16.8px;
              height: 16.8px;
            }
            //width: calc_fluid(16.8, 24, 320, 1920);
            //height: calc_fluid(16.8, 24, 320, 1920);
            transform: none !important;
          }
        }
      }
    }
    &.body {
      width: 281px;
    }
    &.disabled {
      opacity: .6;
      pointer-events: none;
    }
    @include _1320 {
      width: calc(100% / 2 - 15px) !important;
      &.glass {
        width: 100% !important;
      }
      &.year {
        width: 32% !important;
        @include _600 {
          width: 179px !important;
        }
      }
      &.body {
        width: calc(68% - 30px) !important;
        @include _600 {
          width: 100% !important;
        }
      }
      @include _600 {
        width: 100% !important;
      }
    }
    &.active {
      #{$fbi} {
        &-arrow {
          &__icon {
            transform: rotate(180deg);
            fill: $red;
          }
        }
        &-children {
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }
  &-btn {
    font-weight: 900;
    font-size: calc_fluid(11,16,320,1920);
    line-height: calc_fluid(15,24,320,1920);
    margin: 10px 0 0 29px;
    width: 215px;
    height: calc_fluid(40,53,320,1920);
    @include _1600 {
      width: 100%;
      margin: 16px 0 0 10px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: calc_fluid(28, 35, 768, 1920);
    line-height: 36px;
    @include _768 {
      font-size: calc_fluid(20,28,320,768);
      line-height: calc_fluid(26,36,320,768);
    }
    text-align: center;
    color: #fff;
  }
  &__img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}