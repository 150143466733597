@mixin font_t($size, $lineHeight){
  font-size: #{$size}px !important;
  line-height: #{$lineHeight}px !important;
  @include _1170 {
    font-size: calc_fluid($size - ($size / 4), $size, 320, 1920) !important;
    line-height: calc_fluid($lineHeight - ($lineHeight / 4), $lineHeight, 320, 1920) !important;
  }

}
.typical-advantages-item:hover {
  color: #0054A6 !important;
}

.text-form {
  margin: 24px 0 16px !important;
}

.content-text {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -16px;
  & h1, h2, h3, h4, caption {
    font-weight: bold;
    color: #333;
    width: 100%;
  }
  & h2, h3, h4 {
    margin-top: 24px;
  }
  & p {
    color: #333;
    font-size: calc_fluid(14,16,320,1920);
    line-height: calc_fluid(21,24,320,1920);
    margin-bottom: 16px;
    width: 100%;
  }
  & span {
    color: #333;
    font-size: calc_fluid(14,16,320,1920);
    line-height: calc_fluid(21,24,320,1920);
  }
  & img {
    width: 100% !important;
    object-fit: contain;
  }
  & h1 {
    margin-bottom: 28px;
    @include font_t(38, 43)
  }
  & h2 {
    @include font_t(31, 40);
    margin-bottom: 24px;
  }
  & h3 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }
  & h4 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }
  & ol, ul {
    padding-left: 20px;
    margin-bottom: 16px;
    width: 100%;
    & > li {
      width: 100%;
      margin-top: 12px;
      font-size: calc_fluid(14,16,320,1920);
      line-height: calc_fluid(19,22,320,1920);
      &:first-child {
        margin-top: 0;
      }
    }
  }
  & ol {
    & > li {
      list-style-type: decimal;
    }
  }
  & ul {
    & > li {
      position: relative;
      list-style-type: none;
      padding-left: 9px;
      &:before {
        left: -20px;
        top: 0;
        width: 20px;
        height: 20px;
        content: "";
        position: absolute;
        background-image: url("/static/images/check.svg");
        background-size: 19.21px 15.24px;
        background-repeat: no-repeat;
        background-position-y: 3.38px;
      }
    }
  }
  & table {
    margin: 24px 0 16px;
    width: 100%;
    max-width: 100%;
    @include _768 {
      overflow: scroll;
    }
    & tbody, & thead {
      display: block;
      min-width: 600px;
    }
    & > caption {
      @include font_t(24, 28);
      margin-bottom: 18px;
      text-align: left;
    }
    & tr {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      &:first-child {
        & td {
          border-top: 2px solid $red;
        }
      }
    }
    & td, & th {
      padding: 20px;
      display: flex;
      vertical-align: middle;
      flex: 1;
      font-weight:normal;
      font-size: calc_fluid(14,16,320,1920);
      line-height: calc_fluid(21,24,320,1920);
      align-items: center;
      margin: -1px 0 0 -1px;
      border: 1px solid #D5E4F3;
    }
  }
  & a {
    color: #EB5757 !important;
    text-decoration: underline;
    &:hover {
      color: #EB5757 !important;
      text-decoration: none;
    }
    &:visited {
      color: #7A33D4 !important;
      text-decoration: underline;
    }
  }
}

.text_underline {
  margin: 24px 0 16px !important;
  border: 1px solid #0054A6;
  border-radius: 3px;
  width: 100%;
  padding: 35px 55px;
  background: #EBF2F8;
  & > p {
    color: $blu;
  }
}

.file {
  &s {
    margin: 9px -15px 1px;
    display: flex;
    flex-flow: row wrap;
  }
  width: calc(100% / 4 - 30px);
  @include _1170 {
    width: calc(100% / 3 - 30px);
    @include _768 {
      width: calc(100% / 2 - 30px);
      @include _600 {
        width: 100%;
      }
    }
  }
  margin: 15px;
  background: #EBF2F8;
  border-radius: 3px;
  padding: 20px 25px 25px;
  transition: background-color .3s ease-in-out;
  $f:&;
  &-info {
    margin-top: 25px;
    display: flex;
    &-icon {
      &__svg {
        display: flex;
        width: 23.5px;
        fill: none;
        height: 23.5px;
        margin-right: 15px;
        &:last-child {
          display: none;
        }
      }
    }
    &__details {
      font-size: 16px;
      line-height: 23px;
      color: #878787;
      transition: color .3s ease-in-out;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 23px;
    color: $blu;
    transition: color .3s ease-in-out;
  }
  &:hover {
    background: $blu;
    #{$f} {
      &-info {
        &-icon {
          &__svg {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: flex;
            }
          }
        }
        &__details {
          color: #fff;
        }
      }
      &__title {
        color: #fff;
      }
    }
  }
}

.gallery {
  margin: 9px -15px 1px;
  display: flex;
  flex-flow: row wrap;
  &-item {
    margin: 15px;
    display: block;
    $gi:&;
    &_row2 {
      width: calc(100% / 2 - 30px);
      @include _600 {
        width: 100%;
      }
      #{$gi}-img {
        height: calc_fluid(250,495,320,1920);
      }
    }
    &_row3 {
      width: calc(100% / 3 - 30px);
      @include _1170 {
        width: calc(100% / 2 - 30px);
        @include _600 {
          width: 100%;
        }
      }
      #{$gi}-img {
        height: calc_fluid(250,312,320,1920);
      }
    }
    &_row4 {
      width: calc(100% / 4 - 30px);
      @include _1320 {
        width: calc(100% / 3 - 30px);
      }
      @include _1170 {
        width: calc(100% / 2 - 30px);
        @include _600 {
          width: 100%;
        }
      }
      #{$gi}-img {
        height: calc_fluid(250,280,320,1920);
      }
    }
    &-img {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 3px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-hover {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      width: 100%;
      height: 100%;
      background: rgba(6, 72, 151, 0.7);
      opacity: 0;
      transition: opacity .3s ease-in-out;
      &__zoom {
        height: 40px;
        width: 40px;
        fill: none;
      }
    }
    &:hover {
      #{$gi}-hover {
        opacity: 1;
      }
    }
    &__title {
      margin-top: calc_fluid(12,14,320,1920);
      font-size: calc_fluid(12,14, 320,1920);
      line-height: calc_fluid(18,22,320,1920);
      color: #878787;
    }
  }
}

.quote {
  padding: 30px;
  border-radius: 0 5px 5px 0;
  margin: 24px 0 16px;
  background: #F2F5F6;
  border-left: 4px solid $red;
  &-description, &-text {
    color: #333;
    font-size: calc_fluid(14,16,320,1920);
    line-height: calc_fluid(21,24,320,1920);
  }
  &-description {
    font-weight: 600;
    margin-top: 10px;
    font-style: italic;
  }
}

.gallery {
  display: flex;
  flex-flow: row wrap;
  margin: 24px -15px 16px;
  & > div {
    margin: 0 15px;
  }
  &__title {
    @include font_t(15, 20);
    color: #333;
    margin-top: 12px;
  }
  &-image {
    width: 100%;
    display: block;
    & > img {
      width: 100%;
      height:100%;
      object-fit: cover;
    }
  }
  $gallery: &;
  &-{
    &w-2 {
      width: calc(50% - 30px);
      #{$gallery}-image {
        max-height:263px;
        height:263px;
      }
    }
    &w-3 {
      width: calc(33% - 30px);
      #{$gallery}-image {
        max-height:171px;
        height:171px;
      }
    }
    &w-4 {
      width: calc(25% - 30px);
    }
    @include _600 {
      &w-2, &w-3, &w-4 {
        width: 100%;
        margin: 24px 15px 16px !important;
        &:first-child {
          margin: 0 15px 16px !important;
        }
      }
    }
  }
}

.faq {
  display: block;
  margin: 24px 0 16px;
  &-line {
    margin-top: -1px;
    display: block;
    &-header {
      display: flex;
      cursor: pointer;
      border: 1px solid $blu;
      position: relative;
      align-items: center;
      user-select: none;
      padding: calc_fluid(20,29.5,320,1920) calc_fluid(64,95,320,1920) calc_fluid(20,29.5,320,1920) calc_fluid(30,40,320,1920);
      &-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: calc_fluid(64,95,320,1920);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &__svg {
          height: calc_fluid(24,35,320,1920);
          width: calc_fluid(24,35,320,1920);
          transition: transform .3s;
          fill: $blu;
        }
      }
      &__title {
        color: $blu;
        font-weight: 900;
        font-size: calc_fluid(17,20, 320,1920);
        line-height: calc_fluid(20,26,320,1920);
      }
      $fh: &;
      &:hover {
        border: 1px solid $red;
        position: relative;
        z-index: 1;
        #{$fh}__title {
          color: $red;
        }
      }
    }
    &-content {
      display: none;
      padding: calc_fluid(10,17,320,1920) calc_fluid(20,68,320,1920) calc_fluid(20,46,320,1920);
      font-size: calc_fluid(14,16,320,1920);
      line-height: calc_fluid(18,24,320,1920);
      color: $blk;
    }
    $self: &;
    &.active {
      #{$self} {
        &-header {
          background: $blu;
          border-radius: 3px;
          border: 1px solid $blu;
          &-icon {
            &__svg {
              transform: rotateX(180deg);
              fill: #fff;
            }
          }
          &__title {
            color: #fff;
          }
        }
        &-content {
          display: block;
        }
      }
    }
  }
}