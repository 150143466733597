.news {
  width: 100%;
  padding: calc_fluid(60,80,320,1920) 0 calc_fluid(40,100,320,1920);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include _1600 {
    padding: calc_fluid(60,80,320,1920) 15px calc_fluid(40,100,320,1920);
  }
  &.p0 {
    padding: 0;
    @include _1600 {
      padding: 0 15px;
    }
  }
  &-bauble {
    height: 416px;
    border: 1px dashed #878787;
    border-right: none;
    width: 50%;
    position: absolute;
    top: calc_fluid(85,100,1170,1920);
    left: calc_fluid(5, 115, 1170, 1920);
    @include _1170 {
      border:none;
      border-top: 1px dashed #878787;
      top: 32px;
      left: 0;
      width: 100%;
    }
    &-inner {
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__play {
      width: 35px;
      height: 35px;
      margin-top: -17.5px;
      margin-left: 70%;
      fill: none;
      //transition: margin .8s ease-in-out, transform .3s ease-in-out;
      &.typical {
        transform: rotate(55deg);
      }
      @include _1170 {
        margin-left: 30px;
        transform: rotate(180deg);
      }
    }
  }
  &-item {
    width: 345px;
    @include _600 {
      width: 290px;
    }
    border-radius: 3px;
    overflow: hidden;
    display: block;
    border: 1px solid #CDCDCD;
    height: 460px;
    @include _600 {
      height: 439px;
    }
    transition: border .3s, box-shadow .3s;
    margin: 0 15px;
    &-top {
      position: relative;
      height: 209px;
      @include _600 {
        height: 183px;
      }
      width: 100%;
      background: #EBF2F8;
      transition: background-color .3s;
    }
    &__date {
      position: absolute;
      top: 23px;
      left: 27px;
      
      font-style: italic;
      font-size: 18px;
      line-height: 24px;

      color: $blu;

      @include _1170 {
        font-size: 15px;
        line-height: 22px;
        left: 20px;
      }
    }
    &__title {
      position: absolute;
      left: 27px;
      bottom: 29px;
      z-index: 2;
      
      font-weight: bold;
      font-size: 20px;
      text-decoration-line: underline;
      color: $blu;
      line-height: 26px;
      
      max-width: 250px;
      @include _1170 {
        font-size: 18px;
        line-height: 20px;
        left: 20px;
      }
    }
    &__date, &__title {
      transition: color .3s;
    }
    &__text {
      font-size: 16px;
      line-height: 24px;
      color: $blk;
      padding: 20px 26px 20px 27px;
      @include _600 {
        font-size: 14px;
        line-height: 21px;
        padding: 16px 20px 51px;
      }
    }
    &__icon {
      position: absolute;
      top: 2px;
      right: 1px;
      height: 74px;
      width: 194px;
      @include _600 {
        height: 61px;
        width: 161px;
      }
      fill: #DFE9F3;
      transition: fill .3s;
    }
    $ni:&;
    &:hover {
      box-shadow: 0 18px 35px rgba(0, 0, 0, 0.06), 0 17px 34px rgba(0, 0, 0, 0.04);
      border: 1px solid transparent;
      #{$ni} {
        &-top {
          background-color: $red;
        }
        &__date, &__title {
          color: #fff;
        }
        &__icon {
          fill: #F03B63;
        }
      }
    }
  }
  &-items {
    margin-top: 50px;
    z-index: 1;
    margin-left: 15px;
    overflow-x: hidden;
    padding-bottom: calc_fluid(45,30,768,1920);
    @include _768 {
      padding-bottom: 45px;
    }
    background-color: #fff;
    &.typical {
      background: #F9F9F9;
    }
    @include _1170 {
      margin-top: 25px;
    }
    display: flex;
    position: relative;
    width: 1490px;
    @include _1600 {
      width: 100%;
    }
    & .slick-list {
      width: 100%;
      overflow: unset !important;
    }
    & .slick-track {
      display: flex;
    }
    & .slick-dots {
      position: absolute;
      top: calc(100% - 25px);
      width: 100%;
      @include _480 {
        top: calc(100% - 64px);
        width: 290px;
      }
      display: flex;
      justify-content: center;
      margin-right: -11px;
      & > li {
        height: 6px;
        width: 6px;
        marker: none;
        margin-right: 11px;
        display: flex;
        &:first-child:last-child {
          display: none;
        }
        & > button {
          width: 100%;
          height: 100%;
          border: none;
          appearance: none;
          border-radius: 50%;
          background: $borderCD;
          font-size: 0;
        }
        &.slick-active {
          & > button {
            background: $red;
          }
        }
      }
    }
  }
  &__title {
    font-weight: 900;
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding: 0 17px;
    font-size: calc_fluid(20,35,320,1920);
    line-height: 40px;
    &.typical {
      background: #F9F9F9;
    }
    @include _1170 {
      line-height: 26px;
    }
    color: $blk;
  }
  &__btn {
    width: calc_fluid(186,228,320,1920);
    font-weight: 900;
    font-size: 16px;
    z-index: 1;
    line-height: 24px;
    height: calc_fluid(40,53,320,1920);
    @include _768 {
      margin-top: 10px;
      @include _480 {
        margin-top: -24px;
      }
    }
    @include _1170 {
      font-size: 11px;
      line-height: 15px;
    }
  }
}