@import "../mixins";
@import "../media";

.voldemort {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;

  align-items: center;
  height: 886px;

  @include _1920 {
    height: 664px;

    @include _1170 {
      height: 446px;

      @include _600 {
        height: auto;
      }
    }
  }

  &__gradient {
    width: 78%;
    background: linear-gradient(90.15deg, #0B2C69 52.9%, rgba(11, 44, 106, 0) 71.77%);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @include _480 {
      display: none;
    }

    &.edits.korporativ {
      background: none;
    }
  }

  background: linear-gradient(89.15deg, rgba(10, 42, 101, 0.768) 34.99%, rgba(10, 42, 101, 0.664) 99.33%);

  &.image {
    background: none;

    @include _480 {
      background: #0D3173;

      &.edits.korporativ {
        background: none !important;
      }
    }
  }

  @include _480 {
    background: #0D3173;
    flex-direction: column;
  }

  &-content {
    position: relative;
    z-index: 1;
    padding: 0 14px 0 calc_fluid(30, 226, 768, 1920);

    @include _768 {
      padding: 0 14px 0 calc_fluid(15, 30, 320, 768);
    }

    @include _480 {
      padding-top: 27.7px;
    }

    @include _1170 {
      padding-bottom: 40px;
    }

    &-title {
      position: relative;
      font-weight: 900;
      font-size: calc_fluid(22, 38, 320, 1920);
      line-height: calc_fluid(29, 43, 320, 1920);
      color: #FFFFFF;
      margin-top: calc_fluid(3, 0, 320, 1920);
      max-width: calc_fluid(288, 728.1, 320, 1920);

      &.edits.korporativ,
      &.edits.installation-center {
        color: #000;
      }

      &-left {
        position: absolute;
        left: -229px;
        top: 0;
        width: 229px;
        height: 48.66px;
        display: flex;
        align-items: center;

        &-inner {
          height: 1px;
          width: 100%;
          border-top: 1px dashed rgba(255, 255, 255, .5);
          position: relative;
        }

        &__svg {
          position: absolute;
          margin-top: -25px;
          fill: none;
          height: 50px;
          width: 50px;
          margin-left: -10%;
          transform: rotate(120deg);
        }
      }
    }

    &__header {
      font-size: calc_fluid(15, 18, 320, 1920);
      line-height: calc_fluid(22, 42, 320, 1920);
      font-style: italic;
      color: rgba(255, 255, 255, 0.4);
    }

    &__description {
      font-size: calc_fluid(14, 16, 320, 1920);
      line-height: calc_fluid(21, 24, 320, 1920);
      color: #fff;
      margin-top: calc_fluid(15.62, 21.36, 320, 1920);
      max-width: calc_fluid(290, 507, 320, 1920);

      &.edits.korporativ,
      &.edits.installation-center {
        color: #333;
      }

      &>b {
        text-decoration: underline;
      }

      &>a {
        text-decoration: underline;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  &-buttons {
    display: flex;
    margin-top: calc_fluid(24, 40, 320, 1920);

    &.banner-sub{
      flex-wrap: wrap;
    }
  }

  &-register,
  &__callback {
    font-weight: 900;
    font-size: calc_fluid(11, 16, 320, 1920);
    line-height: calc_fluid(15, 24, 320, 1920);
    height: calc_fluid(40, 53, 320, 1920);

   
  }

  &__callback {
    &.banner-sub{
      margin-right: calc(9px + (100vw - 320px) * 0.00625);
    }
    
  }
  &-register.banner-sub{
    text-align: center;
    @media (max-width:510px){
      margin-top: 10px;
    }
  }

  &-border {
    position: relative;

    &-block {
      position: absolute;
      left: 50%;
      top: 100%;
      width: 1575px;
      height: 78px;
      border-left: 1px dashed rgba(255, 255, 255, .5);
      border-bottom: 1px dashed rgba(255, 255, 255, .5);
    }
  }

  &-register {
    width: calc_fluid(140, 243, 320, 1920);

    &.blue {
      background-color: #fff;
      color: #0054A6;
      border: none;

      &:hover {
        background-color: #0054A6;
        color: #fff;
      }
    }
  }

  &__callback {
    width: calc_fluid(140, 219, 320, 1920);
    margin-left: calc_fluid(9, 19, 320, 1920);

    &.red {
      border: 1px solid #ED1847;
      background: #ED1847;
      color: #fff;

      &:hover {
        border: 1px solid #0054A6;
        background-color: #0054A6;
      }
    }
  }

  &__img {
    right: 0;
    top: 0;
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 61.823%;

    &.edits.korporativ {
      height: min-content;
    }

    @include _480 {
      position: unset;
      height: calc_fluid(194.3, 250, 320, 1920);
      z-index: unset;
      width: 100%;
    }

    z-index: -1;
  }
}