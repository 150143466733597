.question {
  width: 100%;
  padding: 30px 75px;
  @include _1600 {
    padding: 30px 15px;
    justify-content: center;
    flex-flow: row wrap;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $blu;
  &-content {
    @include _1600 {
      width: 100%;
    }
  }
  &-form {
    margin-left: 54px;
    height: 100%;
    display: flex;
    align-items: center;
    @include _1600 {
      width: 600px;
      margin-left: 0;
      justify-content: center;
      margin-top: 10px;
      flex-flow: row wrap;
      @include _600 {
        width: 100%;
      }
    }
    &__input {
      border: 1px solid #82ABD3;
      height: calc_fluid(40,53,320,1920);
      background-color: transparent;
      padding-left: 25px;
      color: #fff;
      &:first-child {
        margin-right: 17px;
        @include _600 {
          margin-right: 0;
        }
      }
      &::placeholder {
        color: #82ABD3;
        font-size: 16px;
        line-height: 24px;
        @include _768 {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &.name {
        width: auto_fluid(412, 320, 1920);
      }
      &.phone {
        width: auto_fluid(246,320,1920);
      }
      @include _1600 {
        padding-left: 20px;
        width: calc(50% - 12px) !important;
        margin-top: 10px;
        @include _600 {
          width: 100% !important;
        }
      }
    }
    &__btn {
      width: 233px;
      height: 53px;
      margin-left: 27px;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      @include _1600 {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
        @include _1170 {
          height: 40px;
          font-size: 11px;
          line-height: 15px;
        }
      }
    }
  }
  &-personal, &-personal__link {
    font-size: 14px;
    line-height: 20px;
    color: #82ABD3;
    @include _1170 {
      font-size: 12px;
      line-height: 17px;
    }
  }
  &-personal {
    margin-left: 24px;
    @include _1600 {
      width: 100%;
      margin-left: 0;
      margin-top: 11px;
      text-align: center;
    }
  }
  &-personal__link {
    text-decoration: underline;
  }
  &__title {
    font-weight: 900;
    font-size: calc_fluid(18,24,320,1920);
    line-height: calc_fluid(20,26,320,1920);
    color: #fff;
  }
  &__text {
    margin-top: 13px;
    font-size: 16px;
    line-height: 24px;
    @include _1170 {
      font-size: 14px;
      line-height: 21px;
    }
    color: #fff;
  }
  @include _1600 {
    &__title, &__text {
      text-align: center;
    }
    &__text {
      margin-top: 11px;
    }
  }
}