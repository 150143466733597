@function calc_fluid($min-v, $max-v, $min-w, $max-w) {
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc(#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m});
}
@function calc_fluid_vw($min-v, $max-v, $min-w, $max-w) {
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc((100vw - (100vw - calc(#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m}))) / 2);
}
@function calc_fluid_static($min-v, $max-v, $min-w, $max-w, $static) {
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc((#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m}) + #{$static}px);
}

@function mega_super_calc_fluid_for_departments_slider_arrows_resize($min-v, $max-v, $min-w, $max-w) {
  $fluid: calc_fluid($min-v, $max-v, $min-w, $max-w);
  @return calc(100% - (#{$fluid} * 2) + 20px);
}

@function auto_fluid($max-v, $min-w, $max-w) {
  $min-v: $min-w / 100 * ($max-v / ($max-w / 100));
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc(#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m});
}
