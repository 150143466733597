@import "../env";
@import "../mixins";
@import '../apps/slick';
@import '../components/banner';
@import '../components/filter-block';
@import '../components/departments';
@import '../components/form-group';
@import '../components/news';
@import '../components/question';
@import '../components/base/text_content';
@import '../variables';

.index {
  &-advantages {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    position: relative;
    background: #fff;
    @include _1170 {
      padding: 30px 30px 40px;
      @include _600 {
        padding: 30px 15px 40px;
      }
    }
    &-first-bauble {
      position: absolute;
      top: calc(100% + 22px);
      pointer-events: none;
      left: auto_fluid(-334, 1170, 1920);
      height: auto_fluid(482, 1170, 1920);
      z-index: 2;
      width: auto_fluid(495,1170,1920);
      fill: none;
    }
    &-mobile {
      display: none;
      width: 100%;
      z-index: 1;
      margin-top: 30px;
      position: relative;
      border-top: 1px dashed #757575;
      @include _600 {
        display: block;
      }
      &__icon {
        position: absolute;
        top: -15px;
        right: 27.82px;
        transform: rotate(3deg);
        fill: none;
        height: 30.53px;
        width: 30.53px;
      }
    }
    &-content {
      width: 1444px;
      display: flex;
      padding-top: 20px;
      flex-flow: row wrap;
      @include _1600 {
        width: 1320px;
        padding: 20px 15px 0;
        justify-content: space-between;
        @include _1320 {
          width: 1170px;
          @include _1170 {
            padding: 20px 80px 0;
            width: 100%;
            @include _600 {
              padding: 8px 0 0;
              justify-content: center;
            }
          }
        }
      }
    }
    &-item {
      margin-top: 30px;
      $iai:&;
      display: flex;
      cursor: pointer;
      align-items: center;
      font-style: italic;
      font-weight: 500;
      font-size: calc_fluid(15,18,768,1920);
      line-height: calc_fluid(22,24,768,1920);
      &__icon {
        height: calc_fluid(40,76,320,1920);
        display: flex;
        width: calc_fluid(40,76,320,1920);
        fill: none;
        &:last-child {
          display: none;
        }
      }
      &:hover {
        #{$iai} {
          &__icon {
            &:last-child {
              display: flex;
            }
            &:first-child {
              display: none;
            }
          }
        }
      }
      @include _1170 {
        &__icon {
          &:last-child {
            display: flex;
          }
          &:first-child {
            display: none;
          }
        }
      }
      &__repair {
        max-width: 292px;
        margin-right: 189px;
        #{$iai}__icon {
          margin-right: calc_fluid(19,42,320,1920);
        }
      }
      &__cost {
        margin-right: 162px;
        max-width: 261px;
        #{$iai}__icon {
          margin-right: calc_fluid(19,29,320,1920);
        }
      }
      &__cert {
        max-width: 359px;
        #{$iai}__icon {
          margin-right: calc_fluid(19,29.69,320,1920);
        }
      }
      &__guar {
        margin-left: 240px;
        max-width: 235px;
        #{$iai}__icon {
          margin-right: calc_fluid(19,37,320,1920);
        }
      }
      &__time {
        margin-left: 175px;
        max-width: 291px;
        #{$iai}__icon {
          margin-right: calc_fluid(19,26,320,1920);
        }
      }
      &__stock {
        margin-left: 209px;
        max-width: 293px;
        #{$iai}__icon {
          margin-right: calc_fluid(19,22,320,1920);
        }
      }
      @include _1600 {
        width: calc(100% / 3 - 60px);
        &__repair, &__cost, &__cert, &__guar, &__time, &__stock {
          max-width: unset;
          margin: 30px 0 0 0;
        }
        @include _1170 {
          width: calc(100% / 2 - 60px);
          @include _979 {
            width: calc(100% / 2 - 45px);
            @include _600 {
                width: 222px;
                max-width: 222px;
            }
          }
        }
      }
    }
    &__title {
      font-weight: 900;
      font-size: calc_fluid(28,35,768,1920);
      line-height: calc_fluid(36,38,768,1920);
      @include _768 {
        font-size: calc_fluid(20,28,320,768);
        line-height: calc_fluid(26,36,320,768);
        text-align: center;
      }
      color: $blk;
    }
  }
  &-services {
    padding: calc_fluid(40,80,320,1920) 0;
    background: #F9F9F9;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    @include _1170 {
      padding: calc_fluid(40,80,320,1920) 30px;
      @include _600 {
        padding: calc_fluid(40,80,320,1920) 15px;
      }
    }
    &-first-bauble {
      position: absolute;
      top: auto_fluid(51,1170,1920);
      left: auto_fluid(68,1170,1920);
      width: auto_fluid(122,1170,1920);
      height: auto_fluid(122,1170,1920);
      fill: none;
    }
    &-wrapper {
      width: 1444px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include _1600 {
        width: 1290px;
        @include _1320 {
          width: 1140px;
          @include _1170 {
            width: 100%;
          }
        }
      }
    }
    &-list {
      margin-top: calc_fluid(40,68,768, 1920);
      @include _768 {
        margin-top: 40px;
      }
      width: 100%;
      &-top, &-bottom {
        display: flex;
        @include _1600 {
          flex-flow: row wrap;
        }
        &__img {
          width: 690px;
          height: calc_fluid(435,423,768,1920);
          @include _768 {
            height: calc_fluid(178, 435, 320, 768);
          }
          margin: -27.5px 0;
          border-radius: 3px;
          object-fit: cover;
          @include _1600 {
            width: 100%;
            margin: 0;
            border-radius: 3px 3px 0 0;
          }
        }
        &-content {
          filter: drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.08));
          background: #FFFFFF;
        }
      }
      &-top {
        &-content {
          padding: calc_fluid(28,40,768,1920) calc_fluid(23,90,768,1920) calc_fluid(27,40,768,1920) calc_fluid(23,80,768,1920);
          @include _768 {
            padding: calc_fluid(25,28,320,768) calc_fluid(15,23,320,768) calc_fluid(24,27,320,768) calc_fluid(15,23,320,768);
          }
          clip-path: polygon(0 0, calc(100% - 60px) 0, 100% 60px, 100% 80%, 100% 100%, 20% 100%, 0 100%, 0% 20%);
          @include _1600 {
            clip-path: unset;
          }
        }
        @include _1170 {
          position: relative;
        }
      }
      &-bottom {
        margin-top: calc_fluid(66,143,320,1920);
        @include _1600 {
          flex-flow: column-reverse;
        }

        &-content {
          //padding: calc_fluid(25,40,320,1920) calc_fluid(15,80,320,1920) calc_fluid(24,40,320,1920) calc_fluid(15,90,320,1920);
          padding: calc_fluid(28,40,768,1920) calc_fluid(23,90,768,1920) calc_fluid(27,40,768,1920) calc_fluid(23,80,768,1920);
          @include _768 {
            padding: calc_fluid(25,28,320,768) calc_fluid(15,23,320,768) calc_fluid(24,27,320,768) calc_fluid(15,23,320,768);
          }
          clip-path: polygon(60px 0, 100% 0, 100% 0, 100% 80%, 100% 100%, 20% 100%, 0 100%, 0% 60px);
          @include _1600 {
            clip-path: unset;
          }
        }
      }
      &-btn {
        margin-top: calc_fluid(24,30,320,1920);
        width: 238px;
        @include _768 {
          width: calc_fluid(140,238,320,768);
        }
        height: 53px;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        @include _768 {
          font-size: calc_fluid(11,16,320,768);
          line-height: calc_fluid(15,24,320,768);
          height: calc_fluid(40,53,320,768);
        }
        &-red {
          cursor: pointer;
          width: 238px;
          @include _768 {
            width: calc_fluid(140,238,320,768);
          }
          margin-top: calc_fluid(24,30,320,1920);
          background-color: transparent;
          border: 1px solid $red;
          height: 53px;
          font-weight: 900;
          font-size: 16px;
          line-height: 24px;
          @include _768 {
            font-size: calc_fluid(11,16,320,768);
            line-height: calc_fluid(15,24,320,768);
            height: calc_fluid(40,53,320,768);
          }

          color: $red;
          transition: border .3s, color .3s;
          &:hover {
            border: 1px solid $blu;
            color: $blu;
          }
        }
      }
      &__title {
        font-weight: 900;
        font-size: calc_fluid(24,28,768,1920);
        line-height: calc_fluid(30,36,768,1920);
        @include _768 {
          font-size: calc_fluid(18,24,320,768);
          line-height: calc_fluid(22,30,320,768);
        }
        color: $blk;
      }
      &__text {
        margin-top: calc_fluid(15,14,768,1920);
        font-size: calc_fluid(15,17,768,1920);
        line-height: calc_fluid(21,26,768,1920);
        @include _768 {
          margin-top: calc_fluid(10,15,320,768);
          font-size: calc_fluid(13,15,320,768);
          line-height: calc_fluid(19,21,320,768);
        }
        color: $blk;
      }
    }
    &-voldemort {
      width: 1582px;
      height: 332px;
      position: relative;
      margin-top: calc_fluid(30,109,768,1920);
      @include _768 {
        margin-top: calc_fluid(40,30,320,768);
      }
      overflow: hidden;
      border-radius: 3px;
      @include _1600 {
        width: 1290px;
        @include _1320 {
          width: 1140px;
          @include _1170 {
            width: 100%;
            height: 543px;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      z-index: 1;
      &-content {
        padding: calc_fluid(40,43,320,1920) calc_fluid(25,120,320,1920) calc_fluid(6.46,55,320,1920);
        width: 785px;
        @include _1170 {
          width: 100%;
          @include _768 {
            max-width: 623px;
          }
          position: absolute;
          top: 0;
        }
      }
      &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include _1170 {
          object-fit: fill;
        }
        z-index: -1;
      }
      &__img {
        position: absolute;
        z-index: -1;
        width: 888px;
        height: 269px;
        object-fit: cover;
        left: 621px;
        top: 32px;
        @include _1170 {
          bottom: unset;
          position: absolute;
          top: 10px;
          width: calc(100% - 70px);
          margin-top: 35px;
          object-fit: contain;
          height: calc(100% - 35px);
          left: unset;
          @include _600 {
            width: 100%;
            height: 275px;
            top: unset;
            bottom: 10px;

          }
        }
      }
      &__bg, &__img {
        &.--medium {
          display: none;
          @include _1170 {
            display: block;
          }
        }
        &.--mini {
          display: none;
          @include _600 {
            display: block;
          }
        }
      }
      &__header {
        font-weight: 900;
        font-size: calc_fluid(17,29.8312,320,1920);
        text-transform: uppercase;
        line-height: calc_fluid(21,37,320,1920);
        color: #fff;
        &.big {
          margin-top: -4px;
          font-weight: 900;
          font-size: calc_fluid(35,62.5664,320,1920);
          line-height: calc_fluid(44,77,320,1920);
        }
      }
      &__description {
        margin-top: calc_fluid(18.73, 12.3,320,1920);
        font-size: calc_fluid(13,18,320,1920);
        line-height: calc_fluid(19,28,320,1920);
        color: #BAD1E7;
        & > u {
          color: #fff;
        }
      }

      &__label {
        color: #BAD1E7;
        font-size: 12px;
        line-height: 28px;
      }
      & b {
        color: #FFF;
      }
    }
    &__title {
      font-weight: 900;
      font-size: calc_fluid(28,35,768,1920);
      line-height: calc_fluid(36,38,768,1920);
      @include _768 {
        font-size: calc_fluid(20, 28, 320, 768);
        line-height: calc_fluid(26, 36, 320, 768);
      }
      text-align: center;
      @include _1600 {
        width: 100%;
      }
      & > u {
        &.active {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: -3px;
            width: 3px;
            height: 100%;
            background: #333;
          }
        }
      }

      color: $blk;
    }
    &-bauble {
      position: absolute;
      top: 11px;
      width: calc_fluid(1170, 1764, 1170, 1920);
      @include _1800 {
        width: 100%;
      }
      height: 685px;
      border-bottom: 1px dashed #878787;

      border-right: 1px dashed #878787;
      left: calc_fluid(-300, 0, 768,1920);
      @include _1600 {
        height: calc_fluid(1000, 1010, 1170, 1600);
        @include _1170 {
          height: 100%;
          left: -30px;
          width: calc(100% + 80px);
          top: calc_fluid(33, 53.5, 320, 1170);
        }
      }
      display: flex;
      justify-content: flex-end;
      &__icon {
        width: 34.45px;
        height: 34.45px;
        margin-right: -17.5px;
        margin-bottom: -17.225px;
        transform: rotate(-90deg);
        fill: none;
      }
    }
    &__description {
      margin-top: calc_fluid(18,20,768,1920);
      width: 1324px;
      @include _1600 {
        width: 100%;
      }
      text-align: center;
      @include _1170 {
        text-align: unset;
      }
      font-size: calc_fluid(16,17,768,1920);
      line-height: calc_fluid(24,26,768,1920);
      @include _768 {
        margin-top: calc_fluid(25,18,320,768);
        font-size: calc_fluid(14,16,320,768);
        line-height: calc_fluid(21,24,320,768);
      }
      color: #878787;
      & > b {
        color: $red;
      }
    }
  }
  &-additional {
    margin-top: calc_fluid(-265,-285,320,1920);
    @include _1170 {
      margin-top:calc_fluid_static(-60,-80,320,1920,20)
    }
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    &-second-bauble {
      position: absolute;
      top: auto_fluid(378,1170,1920);
      left: auto_fluid(24,1170,1920);
      width: auto_fluid(96,1170,1920);
      height: auto_fluid(96,1170,1920);
      fill: none;
      @include _1600 {
        top: 140px;
      }
    }
    &-first-bauble {
      position: absolute;
      top: auto_fluid(110,1170,1920);
      @include _1600 {
        top: -70px;
      }
      pointer-events: none;
      left: auto_fluid(-123, 1170, 1920);
      height: auto_fluid(239, 1170, 1920);
      z-index: 1;
      width: auto_fluid(245,1170,1920);
      fill: none;
    }
    &-wrapper {
      width: auto_fluid(1470, 1170, 1920);
      @include _1600 {
        width: 100%;
        padding-right: 30px;
        margin-left: 30px;
        @include _600 {
          padding-right: 15px;
          margin-left: 15px;
        }
      }
      position: relative;
      padding-bottom: 80px;
      &-inner {
        margin-top: 281px;
        @include _1170 {
          max-width: calc_fluid(530,585,600,768);
          margin-top: 60px;
          @include _600 {
            max-width: unset;
          }
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    &-obs {
      position: absolute;
      top: 240px;
      width: calc_fluid(480,670,1170,1920);
      max-width: calc_fluid(480,670,1170,1920);
      left: 0;
      &-inner {
        width: 100%;
        position: relative;
      }
      &__arrow {
        position: absolute;
        right: -19px;
        top: 50%;
        transform: rotate(270deg);
        height: 35px;
        width: 35px;
        fill: none;
      }
      @include _1170 {
        position: unset;
        width: 100%;
        max-width: unset;
      }
      &__header {
        font-size: 18px;
        line-height: 42px;
        color: #878787;
        @include _1170 {
          font-size: 15px;
          line-height: 22px;
        }
      }
      &__title {
        margin-top: -2px;
        font-weight: 900;
        font-size: calc_fluid(20,35,320,1920);
        line-height: calc_fluid(26,40,320,1920);
        color: $blk;
        @include _1170 {
          margin-top: 3px;
        }
        & > b {
          color: $red;
        }
      }
      &__description {
        font-size: calc_fluid(14,17,320,1920);
        line-height: calc_fluid(21,26,320,1920);
        color: #878787;
        margin-top: 20px;
        max-width: 594px;
        @include _1170 {
          max-width: unset;
        }
      }
      &__btn {
        margin-top: calc_fluid(20,40,320,1920);
        height: calc_fluid(40,53, 320, 1920);
        width: calc_fluid(155,238,320,1920);
        font-weight: 900;
        font-size: calc_fluid(11,16,320,1920);
        line-height: calc_fluid(15,24,320,1920);
      }
    }
    &-item {
      width: calc_fluid(600,720,1170,1920);
      border-radius: 3px;
      display: block;
      overflow: hidden;
      transition: background-color .3s, box-shadow .3s;
      background: #EBF2F8;
      margin-top: calc_fluid(15,30,320,1920);
      @include _1170 {
        width: 100%;
      }
      &:first-child {
        margin-top: 42px;
      }
      &-content {
        padding: 58px 96px 50px 60px;
        @include _1170 {
          padding: 57px calc_fluid(20, 45, 320, 1170) calc_fluid(22,35,320,1920);
        }
      }
      &-details {
        display: flex;
        align-items: center;
        margin-top: calc_fluid(22,30,320,1920);
        &__text {
          font-weight: bold;
          font-size: calc_fluid(11,18,320,1920);
          line-height: calc_fluid(15,24,320,1920);
          padding: 2px 0;
          margin-right: 12px;
          transition: color .3s;
          color: $blu;
        }
        &__icon {
          transform: rotate(-90deg);
          height: calc_fluid(14,24,320,1920);
          width: calc_fluid(14,24,320,1920);
          transition: fill .3s;
          fill: $blu;
        }
      }
      &__title {
        position: absolute;
        left: 0;
        bottom: -43px;
        height: 77px;
        transition: background-color .3s;
        width: 526px;
        padding: 0 39px 15px;
        background: $blu;
        font-weight: 900;
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 26px;
        @include _1170 {
          font-size: 18px;
          line-height: 24px;
          @include _768 {
            font-size: calc_fluid(14,18,320,768);
            line-height: calc_fluid(18,24,320,768);
          }
        }

        color: #FFFFFF;
       // clip-path: polygon(0% 0%, 100% 0%, calc(100% - 25px) calc(100% - 14px), 81px calc(100% - 14px), 59px 100%, 59px calc(100% - 14px), 0% calc(100% - 14px));
        mask: url('/static/images/clipped.svg');
        @include _600 {
          mask: url('/static/images/clipped_m.svg');
          width: 250px;
          height: 70px;
          display: flex;
          align-items: center;
          padding: 0 5px 12px 10px;
        }
        mask-size: 100% 100%;
      }
      &__description {
        font-size: calc_fluid(13,16,320,1920);
        line-height: calc_fluid(19,24,320,1920);
        min-height: 119px;
        @include _1170 {
          min-height: unset;
        }
        color: $blk;
      }
      &-image {
        height: calc_fluid(150,372,320,1920);
        width: 100%;
        position: relative;
        &__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      $iasi:&;
      &:hover {
        background: #fff;
        box-shadow: 0px 18px 35px rgba(0, 0, 0, 0.06), 0px 17px 34px rgba(0, 0, 0, 0.04);
        #{$iasi} {
          &-details {
            &__text {
              color: $red;
            }
            &__icon {
              fill: $red;
            }
          }
          &__title {
            background: $red;
          }
        }
      }
    }
    &-line {
      top: 0;
      left: 0;
      position: absolute;
      width: calc_fluid(480,670,1170,1920);
      height: 100%;
      border-right: 1px dashed #878787;
      @include _1170 {
        width: calc(100% - #{calc_fluid(15,71,600,768)});
        @include _600 {
          display: none;
        }
      }
      &-inner {
        position: relative;
        height: 100%;
      }
      &__arrow {
        position: absolute;
        right: -19px;
        top: 151px;
        transform: rotate(270deg);
        height: 35px;
        width: 35px;
        fill: none;
      }
    }

  }
  &-reviews {
    padding: calc_fluid(60,80,768,1920) calc_fluid(15,0,768,1920);
    @include _768 {
      padding: calc_fluid(40,60,320,768) 0 calc_fluid(40,60,320,768) 20px;
      @include _600 {
        padding-left: 0;
      }
    }
    position: relative;
    background: rgba(0, 84, 166, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    &-first-bauble {
      position: absolute;
      bottom: auto_fluid(-119,1170,1920);
      pointer-events: none;
      right: auto_fluid(-261, 1170, 1920);
      height: auto_fluid(389, 1170, 1920);
      z-index: 1;
      width: auto_fluid(399,1170,1920);
      fill: none;
    }
    &-item {
      //width: calc_fluid(390,725,800,1920);
      width: calc_fluid_vw(1064,1490,1170,1920);
      height: calc_fluid(512,388,320,1920);
      padding: 0 10px;
      display: block;
      @include _1170 {
        width: calc((100vw - 30px) / 2);
        padding: 0 15px;
        margin-left: 10px;
      }
      @include _768 {
        margin-left: 0;
        width: calc(100vw - 40px);
        @include _600 {
          width: 100vw;
        }
        height: unset;
      }
      &-inner {
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 3px;
        padding: 20px calc_fluid(20,27,320,1920) calc_fluid(95,113,320,1920) calc_fluid(20,38,320,1920);
        position: relative;
      }
      &-top {
        display: flex;
        justify-content: space-between;
        @include _1170 {
          flex-direction: row;
        }
        align-items: center;
        &-left {
          display: flex;
          align-items: center;
        }
      }
      &-header {
        display: flex;
        margin-top: 16px;
        align-items: center;
        @include _1170 {
          flex-direction: column-reverse;
          align-items: unset;
        }
      }
      &__text {
        margin-top: 16px;
        font-size: calc_fluid(14,16,320,1920);
        line-height: calc_fluid(21,24,320,1920);
        color: #878787;
        & > b {
          color: $red;
        }
      }
      &__btn {
        position: absolute;
        bottom: 30px;
        left: calc_fluid(20,38,320,1920);
        width: 238px;
        height: calc_fluid(40,53, 320,1920);
        font-weight: 900;
        font-size: calc_fluid(11,16,320,1920);
        line-height: calc_fluid(15,24,320,1920);
        @include _1170 {
          width: 250px;
        }
      }
      &__name {
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        @include _1170 {
          margin-top: 8px;
          font-size: 18px;
          line-height: 20px;
        }
        color: $blk;
      }
      &__date {
        font-size: 16px;
        line-height: 24px;
        color: #878787;
        margin-left: 10px;
        @include _1170 {
          font-size: 15px;
          line-height: 22px;
          margin-left: 0;
        }
      }
      &-icon {
      object-fit: cover;
      margin-right: 13px;
      @include _1170 {
        margin-right: 8px;
      }
      &__icon {
        height: 100%;
        width: 100%;
        &:last-child {
          display: none;
        }
      }
      &.geo {
        width: 16px;
        height: 27px;
        @include _1170 {
          width: 13px;
          height: 22px;
          margin-right: 13px;
        }
      }
      &.gis {
        width: 32px;
        height: 32px;
        @include _1170 {
          width: 26px;
          height: 26px;
        }
      }
      &.drive {
        width: 60px;
        height: 25px;
        @include _1170 {
          height: 21px;
          width: 49px;
        }
      }
      &.ph {
        width: 154px;
        height: 50.39px;
        @include _1170 {
          width: 108px;
          height: 35.34px;
        }
      }
      $icon:&;
      &:hover {
        #{$icon}__icon {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: block;
          }
        }
      }
      &.ph {
        width: 154px;
        height: 50.39px;
        @include _1170 {
          width: 108px;
          height: 35.34px;
        }
        #{$icon}__icon {
          &:first-child:last-child {
            display: block !important;
          }
        }
      }
    }
    }
    &-slider {
      width: calc_fluid(1170, 1627, 1170, 1920);
      @include _1170 {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      position: relative;
    }
    &-arrows {
      position: absolute;
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      &-prev, &-next {
        width: 48px;
        height: 48px;
        cursor: pointer;
        border: 1px solid #fff;
        display: flex;
        background: none;
        align-items: center;
        justify-content: center;
        @include _1170 {
          display: none;
        }
        &__icon {
          width: 30px;
          height: 30px;
          fill: #fff;
        }
        $ira:&;
        &:hover {
          background: #fff;
          border: 1px solid #fff;
          #{$ira} {
            &__icon {
              fill: $blu;
            }
          }
        }
      }
      &-prev {
        transform: rotate(45deg);
        &__icon {
          transform: rotate(45deg);
        }
      }
      &-next {
        transform: rotate(-45deg);
        &__icon {
          transform: rotate(-45deg);
        }
      }
    }
    &-items {
      width: calc_fluid(1064,1490,1170,1920);
      overflow: hidden;
      z-index: 2;
      @include _1170 {
        width: 100%;
      }
      position: relative;
      @include _600 {
        width: calc(100% + 30px);
      }
      margin-top: 40px;
      @include _1170 {
        margin-top: 25px;
      }
      display: flex;
      padding-bottom: 26px;
      & .slick-dots {
        position: absolute;
        top: calc(100% - 6px);
        width: 100%;
        @include _480 {
          top: calc(100% - 25px);
          width: 290px;
        }
        display: flex;
        justify-content: center;
        margin-right: -11px;
        & > li {
          height: 6px;
          width: 6px;
          marker: none;
          margin-right: 11px;
          display: flex;
          &:first-child:last-child {
            display: none;
          }
          & > button {
            width: 100%;
            height: 100%;
            border: none;
            appearance: none;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.29);
            font-size: 0;
          }
          &.slick-active {
            & > button {
              background: #fff;
            }
          }
        }
      }
    }
    &__title {
      font-weight: 900;
      font-size: calc_fluid(20,35,320,1920);
      line-height: calc_fluid(26,40,320,1920);
      text-align: center;
      color: #fff;
    }
    &__img {
      position: absolute;
      left: 0;
      z-index: -1;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-seo {

    &.blog-hidden {
        margin-top: calc_fluid(40,80,320,1920);
    }
    display: flex;
    flex-direction: column;
    padding-bottom: calc_fluid(40,100,320,1920);
    align-items: center;
    &-wrapper {
      @include defaultWrapper;
      margin-top: 40px;
      padding: 0 15px;
      display: flex;
      @include _1170 {
        flex-flow: row wrap;
      }
    }
    &-content {
      margin-left: 40px;
      width: 100%;
      height: calc_fluid(300, 360, 1170, 1920);
      &-text {
        padding-right: 31px;
      }
      @include _1170 {
        margin-left: 0;
        margin-top: 20px;
      }
    }
    &__image {
      border-radius: 3px;
      object-fit: cover;
      width: auto_fluid(694, 1170, 1920);
      min-width: auto_fluid(694, 1170, 1920);
      height: auto_fluid(360, 1170, 1920);
      @include _1170 {
        width: 100%;
        height: 300px;
      }
    }
    &__title {
      font-weight: 900;
      font-size: calc_fluid(20,35,320,1920);
      line-height: calc_fluid(26,38,320,1920);
      text-align: center;
      width: 100%;
      color: $blk;
    }
  }
}