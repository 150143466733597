/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.voldemort {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 886px;
  background: linear-gradient(89.15deg, rgba(10, 42, 101, 0.768) 34.99%, rgba(10, 42, 101, 0.664) 99.33%); }
  @media (max-width: 1920px) {
    .voldemort {
      height: 664px; } }
  @media (max-width: 1920px) and (max-width: 1170px) {
    .voldemort {
      height: 446px; } }
  @media (max-width: 1920px) and (max-width: 1170px) and (max-width: 600px) {
    .voldemort {
      height: auto; } }
  .voldemort__gradient {
    width: 78%;
    background: linear-gradient(90.15deg, #0B2C69 52.9%, rgba(11, 44, 106, 0) 71.77%);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
    @media (max-width: 480px) {
      .voldemort__gradient {
        display: none; } }
    .voldemort__gradient.edits.korporativ {
      background: none; }
  .voldemort.image {
    background: none; }
    @media (max-width: 480px) {
      .voldemort.image {
        background: #0D3173; }
        .voldemort.image.edits.korporativ {
          background: none !important; } }
  @media (max-width: 480px) {
    .voldemort {
      background: #0D3173;
      flex-direction: column; } }
  .voldemort-content {
    position: relative;
    z-index: 1;
    padding: 0 14px 0 calc(30px + (100vw - 768px) * 0.17014); }
    @media (max-width: 768px) {
      .voldemort-content {
        padding: 0 14px 0 calc(15px + (100vw - 320px) * 0.03348); } }
    @media (max-width: 480px) {
      .voldemort-content {
        padding-top: 27.7px; } }
    @media (max-width: 1170px) {
      .voldemort-content {
        padding-bottom: 40px; } }
    .voldemort-content-title {
      position: relative;
      font-weight: 900;
      font-size: calc(22px + (100vw - 320px) * 0.01);
      line-height: calc(29px + (100vw - 320px) * 0.00875);
      color: #FFFFFF;
      margin-top: calc(3px + (100vw - 320px) * -0.00187);
      max-width: calc(288px + (100vw - 320px) * 0.27506); }
      .voldemort-content-title.edits.korporativ, .voldemort-content-title.edits.installation-center {
        color: #000; }
      .voldemort-content-title-left {
        position: absolute;
        left: -229px;
        top: 0;
        width: 229px;
        height: 48.66px;
        display: flex;
        align-items: center; }
        .voldemort-content-title-left-inner {
          height: 1px;
          width: 100%;
          border-top: 1px dashed rgba(255, 255, 255, 0.5);
          position: relative; }
        .voldemort-content-title-left__svg {
          position: absolute;
          margin-top: -25px;
          fill: none;
          height: 50px;
          width: 50px;
          margin-left: -10%;
          transform: rotate(120deg); }
    .voldemort-content__header {
      font-size: calc(15px + (100vw - 320px) * 0.00187);
      line-height: calc(22px + (100vw - 320px) * 0.0125);
      font-style: italic;
      color: rgba(255, 255, 255, 0.4); }
    .voldemort-content__description {
      font-size: calc(14px + (100vw - 320px) * 0.00125);
      line-height: calc(21px + (100vw - 320px) * 0.00187);
      color: #fff;
      margin-top: calc(15.62px + (100vw - 320px) * 0.00359);
      max-width: calc(290px + (100vw - 320px) * 0.13562); }
      .voldemort-content__description.edits.korporativ, .voldemort-content__description.edits.installation-center {
        color: #333; }
      .voldemort-content__description > b {
        text-decoration: underline; }
      .voldemort-content__description > a {
        text-decoration: underline;
        color: #fff;
        font-weight: bold; }
  .voldemort-buttons {
    display: flex;
    margin-top: calc(24px + (100vw - 320px) * 0.01); }
    .voldemort-buttons.banner-sub {
      flex-wrap: wrap; }
  .voldemort-register, .voldemort__callback {
    font-weight: 900;
    font-size: calc(11px + (100vw - 320px) * 0.00313);
    line-height: calc(15px + (100vw - 320px) * 0.00562);
    height: calc(40px + (100vw - 320px) * 0.00813); }
  .voldemort__callback.banner-sub {
    margin-right: calc(9px + (100vw - 320px) * 0.00625); }
  .voldemort-register.banner-sub {
    text-align: center; }
    @media (max-width: 510px) {
      .voldemort-register.banner-sub {
        margin-top: 10px; } }
  .voldemort-border {
    position: relative; }
    .voldemort-border-block {
      position: absolute;
      left: 50%;
      top: 100%;
      width: 1575px;
      height: 78px;
      border-left: 1px dashed rgba(255, 255, 255, 0.5);
      border-bottom: 1px dashed rgba(255, 255, 255, 0.5); }
  .voldemort-register {
    width: calc(140px + (100vw - 320px) * 0.06438); }
    .voldemort-register.blue {
      background-color: #fff;
      color: #0054A6;
      border: none; }
      .voldemort-register.blue:hover {
        background-color: #0054A6;
        color: #fff; }
  .voldemort__callback {
    width: calc(140px + (100vw - 320px) * 0.04938);
    margin-left: calc(9px + (100vw - 320px) * 0.00625); }
    .voldemort__callback.red {
      border: 1px solid #ED1847;
      background: #ED1847;
      color: #fff; }
      .voldemort__callback.red:hover {
        border: 1px solid #0054A6;
        background-color: #0054A6; }
  .voldemort__img {
    right: 0;
    top: 0;
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 61.823%;
    z-index: -1; }
    .voldemort__img.edits.korporativ {
      height: min-content; }
    @media (max-width: 480px) {
      .voldemort__img {
        position: unset;
        height: calc(194.3px + (100vw - 320px) * 0.03481);
        z-index: unset;
        width: 100%; } }

.k-scroll-block {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  user-select: none; }

.k-scroll-track {
  width: 100%;
  padding-right: 13px;
  overflow: scroll; }
  .k-scroll-track::-webkit-scrollbar {
    display: none; }
  .k-scroll-track::-moz-viewport-scroll {
    display: none; }

.k-scroll-bar {
  width: 5px;
  margin-top: 5px;
  height: calc(100% - 10px);
  background: #F9F9F9;
  border-radius: 9px;
  position: relative; }
  .k-scroll-bar.hidden {
    display: none; }
  .k-scroll-bar-slider {
    left: 0;
    top: 0;
    position: absolute;
    width: 5px;
    background: #0054A6;
    border-radius: 9px; }

.filter-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 70px 0;
  background: rgba(0, 84, 166, 0.8); }
  @media (max-width: 1170px) {
    .filter-block {
      padding: 60px 30px; } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .filter-block {
      padding: 40px 15px; } }
  .filter-block-wrapper {
    padding: 38px 40px 38px 34px;
    margin-top: 40px;
    background: #fff;
    border-radius: 3px;
    width: 1470px;
    display: flex;
    flex-flow: row wrap; }
    @media (max-width: 600px) {
      .filter-block-wrapper {
        padding: 20px 24px 30px 14px; } }
    @media (max-width: 1600px) {
      .filter-block-wrapper {
        width: 1290px;
        justify-content: space-between; } }
  @media (max-width: 1600px) and (max-width: 1320px) {
    .filter-block-wrapper {
      width: 1140px; } }
  @media (max-width: 1600px) and (max-width: 1320px) and (max-width: 1170px) {
    .filter-block-wrapper {
      margin-top: 30px;
      width: 100%; } }
  .filter-block-item {
    height: 53px;
    background: #fff;
    position: relative;
    border: 1px solid #878787;
    user-select: none;
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin: 10px 0 0 10px;
    cursor: pointer; }
    @media (max-width: 600px) {
      .filter-block-item {
        height: 40px; } }
    .filter-block-item-arrow {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 58px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 600px) {
        .filter-block-item-arrow {
          width: 40px; } }
      .filter-block-item-arrow__icon {
        transition: transform .3s, fill .3s;
        width: 20px;
        height: 20px;
        fill: #333; }
        @media (max-width: 600px) {
          .filter-block-item-arrow__icon {
            width: 16px;
            height: 16px; } }
    .filter-block-item-children {
      position: absolute;
      user-select: none;
      max-height: 300px;
      top: 100%;
      width: 100%;
      background: #fff;
      border: 1px solid #CDCDCD;
      display: flex;
      opacity: 0;
      z-index: -1;
      padding: 13px 13px 13px 27px;
      cursor: default; }
      .filter-block-item-children.default {
        display: block; }
      .filter-block-item-children.scroll_h {
        height: 300px; }
      .filter-block-item-children__item {
        padding: 5px 0;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        cursor: pointer;
        user-select: none;
        transition: color .3s; }
        .filter-block-item-children__item:hover {
          color: #ED1847; }
    .filter-block-item__text {
      font-size: 16px;
      line-height: 24px;
      user-select: none;
      padding-left: 20px; }
      @media (max-width: 600px) {
        .filter-block-item__text {
          font-size: 14px;
          line-height: 22px; } }
    .filter-block-item.glass {
      width: 260px; }
    .filter-block-item.mark {
      width: 195px; }
    .filter-block-item.model {
      width: 189px; }
    .filter-block-item.year {
      width: 177px; }
      .filter-block-item.year .filter-block-item-arrow {
        width: 48px;
        border-left: 1px solid #878787; }
        .filter-block-item.year .filter-block-item-arrow__icon {
          width: 24px;
          height: 24px;
          transform: none !important; }
          @media (max-width: 600px) {
            .filter-block-item.year .filter-block-item-arrow__icon {
              width: 16.8px;
              height: 16.8px; } }
    .filter-block-item.body {
      width: 281px; }
    .filter-block-item.disabled {
      opacity: .6;
      pointer-events: none; }
    @media (max-width: 1320px) {
      .filter-block-item {
        width: calc(100% / 2 - 15px) !important; }
        .filter-block-item.glass {
          width: 100% !important; }
        .filter-block-item.year {
          width: 32% !important; } }
    @media (max-width: 1320px) and (max-width: 600px) {
      .filter-block-item.year {
        width: 179px !important; } }
    @media (max-width: 1320px) {
        .filter-block-item.body {
          width: calc(68% - 30px) !important; } }
    @media (max-width: 1320px) and (max-width: 600px) {
      .filter-block-item.body {
        width: 100% !important; } }
  @media (max-width: 1320px) and (max-width: 600px) {
    .filter-block-item {
      width: 100% !important; } }
    .filter-block-item.active .filter-block-item-arrow__icon {
      transform: rotate(180deg);
      fill: #ED1847; }
    .filter-block-item.active .filter-block-item-children {
      opacity: 1;
      z-index: 1; }
  .filter-block-btn {
    font-weight: 900;
    font-size: calc(11px + (100vw - 320px) * 0.00313);
    line-height: calc(15px + (100vw - 320px) * 0.00562);
    margin: 10px 0 0 29px;
    width: 215px;
    height: calc(40px + (100vw - 320px) * 0.00813); }
    @media (max-width: 1600px) {
      .filter-block-btn {
        width: 100%;
        margin: 16px 0 0 10px; } }
  .filter-block__title {
    font-weight: 900;
    font-size: calc(28px + (100vw - 768px) * 0.00608);
    line-height: 36px;
    text-align: center;
    color: #fff; }
    @media (max-width: 768px) {
      .filter-block__title {
        font-size: calc(20px + (100vw - 320px) * 0.01786);
        line-height: calc(26px + (100vw - 320px) * 0.02232); } }
  .filter-block__img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1; }

.departments {
  padding: calc(60px + (100vw - 320px) * 0.0125) 0 calc((60px + (100vw - 320px) * 0.0125) + -20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .departments-line {
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    overflow: hidden;
    border-top: 1px dashed #878787; }
    @media (max-width: 1600px) {
      .departments-line {
        top: 43px; } }
    .departments-line-inner {
      position: relative; }
    .departments-line__svg {
      position: absolute;
      left: -5%;
      fill: none;
      height: 34.5px;
      transform: rotate(180deg);
      transition: left 4s ease-in-out;
      width: 34.5px;
      top: -19px; }
      @media (max-width: 1600px) {
        .departments-line__svg {
          right: 0;
          left: 125px; } }
  @media (max-width: 1600px) and (max-width: 600px) {
    .departments-line__svg {
      left: 30px; } }
  .departments-map {
    margin-top: 45px;
    position: relative;
    height: 871px;
    width: 100%; }
    @media (max-width: 1170px) {
      .departments-map {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 25px;
        height: unset; } }
    .departments-map-block {
      width: 100%;
      height: calc(364px + (100vw - 320px) * 0.2175);
      min-height: calc(364px + (100vw - 320px) * 0.2175); }
    .departments-map-interface {
      position: absolute;
      left: 0;
      width: 100%;
      height: calc(100% - 30px);
      top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1170px) {
        .departments-map-interface {
          position: unset; } }
      .departments-map-interface-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 1469px; }
        @media (max-width: 1700px) {
          .departments-map-interface-wrapper {
            width: 1115px; } }
  @media (max-width: 1700px) and (max-width: 1170px) {
    .departments-map-interface-wrapper {
      width: 100%;
      padding: 0 15px;
      margin-top: 20px; } }
      .departments-map-interface-form {
        padding: calc(30px + (100vw - 320px) * 0.00813) calc(24px + (100vw - 320px) * 0.00375);
        height: fit-content;
        width: 484px;
        z-index: 1;
        background: #fff;
        box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.15);
        border-radius: 3px; }
        @media (max-width: 1170px) {
          .departments-map-interface-form {
            width: 100%; } }
        .departments-map-interface-form-fields {
          margin-top: calc(16px + (100vw - 320px) * 0.00875); }
          @media (max-width: 1170px) {
            .departments-map-interface-form-fields {
              display: flex;
              justify-content: space-between; }
              .departments-map-interface-form-fields > .form-group {
                width: calc(50% - 8px);
                margin-top: 0; } }
  @media (max-width: 1170px) and (max-width: 480px) {
    .departments-map-interface-form-fields {
      display: block; }
      .departments-map-interface-form-fields > .form-group {
        width: 100%;
        margin-top: 10px; } }
        .departments-map-interface-form-personal, .departments-map-interface-form-personal__link {
          font-size: calc(12px + (100vw - 320px) * 0.00187);
          line-height: calc(17px + (100vw - 320px) * 0.0025); }
        .departments-map-interface-form-personal__link {
          text-decoration: underline; }
        .departments-map-interface-form-personal {
          color: #A3A3A3;
          margin-top: calc(8px + (100vw - 320px) * 0.00313); }
          .departments-map-interface-form-personal__link {
            color: #A3A3A3; }
            .departments-map-interface-form-personal__link:hover {
              color: #ED1847; }
        .departments-map-interface-form__btn {
          margin-top: calc(16px + (100vw - 320px) * 0.00187);
          height: calc(40px + (100vw - 320px) * 0.00813);
          width: 100%;
          font-weight: 900;
          font-size: calc(11px + (100vw - 320px) * 0.00313);
          line-height: calc(15px + (100vw - 320px) * 0.00562); }
        .departments-map-interface-form__title {
          font-size: calc(18px + (100vw - 320px) * 0.00625);
          font-weight: 900;
          line-height: calc(22px + (100vw - 320px) * 0.00875);
          text-align: center; }
        .departments-map-interface-form__description {
          margin-top: calc(12px + (100vw - 320px) * -0.00313);
          font-size: calc(14px + (100vw - 320px) * 0.00125);
          line-height: calc(21px + (100vw - 320px) * 0.00187);
          color: #6D6D6D;
          text-align: center; }
      .departments-map-interface-slider {
        height: 338px;
        width: 1622px;
        display: flex;
        justify-content: center;
        z-index: 1;
        position: relative; }
        .departments-map-interface-slider.centres-edits {
          width: 1270px; }
          @media (max-width: 1170px) {
            .departments-map-interface-slider.centres-edits {
              width: 100%; } }
          @media (min-width: 970px) {
            .departments-map-interface-slider.centres-edits {
              align-items: center;
              justify-content: center;
              position: absolute;
              top: -25%;
              left: 0;
              right: 0;
              margin: auto; }
              .departments-map-interface-slider.centres-edits .departments-map-interface-slider-slides {
                margin: 0; } }
        @media (max-width: 1700px) {
          .departments-map-interface-slider {
            width: 100%; } }
  @media (max-width: 1700px) and (max-width: 1320px) {
    .departments-map-interface-slider {
      width: 1140px; } }
  @media (max-width: 1700px) and (max-width: 1320px) and (max-width: 1170px) {
    .departments-map-interface-slider {
      width: 100%;
      height: 284px;
      margin-top: 20px; } }
        .departments-map-interface-slider-slides {
          max-width: 1130px;
          margin-right: 100px;
          display: flex;
          overflow: hidden; }
          @media (max-width: 1700px) {
            .departments-map-interface-slider-slides {
              margin-right: 0;
              max-width: 1115px; } }
  @media (max-width: 1700px) and (max-width: 1170px) {
    .departments-map-interface-slider-slides {
      max-width: 925px; } }
  @media (max-width: 1700px) and (max-width: 1170px) and (max-width: 979px) {
    .departments-map-interface-slider-slides {
      max-width: 100%; } }
          .departments-map-interface-slider-slides .slick-list {
            width: 100%; }
          .departments-map-interface-slider-slides .slick-track {
            display: flex; }
        .departments-map-interface-slider-slide {
          padding: 0 10px; }
          @media (max-width: 600px) {
            .departments-map-interface-slider-slide {
              padding: 0 15px;
              max-width: 310px; } }
          .departments-map-interface-slider-slide-inner {
            position: relative;
            border: 1px solid #0054A6;
            border-radius: 3px;
            background: #fff;
            width: 352px;
            transition: box-shadow .3s, border .3s;
            height: 318px;
            padding: calc(15px + (100vw - 320px) * 0.00937) calc(15px + (100vw - 320px) * 0.00813) calc(24px + (100vw - 320px) * 0.00375); }
            @media (max-width: 1170px) {
              .departments-map-interface-slider-slide-inner {
                width: 290px;
                height: 264px; } }
  @media (max-width: 1170px) and (max-width: 480px) {
    .departments-map-interface-slider-slide-inner {
      width: 100%; } }
          .departments-map-interface-slider-slide-geo {
            position: absolute;
            top: 0;
            right: 0;
            height: calc(35.82px + (100vw - 320px) * 0.01386);
            width: calc(35.51px + (100vw - 320px) * 0.01249);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; }
            .departments-map-interface-slider-slide-geo__icon {
              height: calc(14px + (100vw - 320px) * 0.00438);
              width: calc(14px + (100vw - 320px) * 0.00438);
              fill: #333; }
            .departments-map-interface-slider-slide-geo:hover .departments-map-interface-slider-slide-geo__icon {
              fill: #ED1847; }
          .departments-map-interface-slider-slide-bottom {
            margin-top: 16px;
            display: flex;
            justify-content: space-between; }
            .departments-map-interface-slider-slide-bottom__ph {
              width: calc(173px + (100vw - 320px) * 0.0075);
              height: calc(58px + (100vw - 320px) * 0.00562);
              object-fit: cover; }
            @media (max-width: 1170px) {
              .departments-map-interface-slider-slide-bottom {
                margin-top: 20px; } }
          .departments-map-interface-slider-slide-btn {
            width: calc(62px + (100vw - 320px) * 0.01688);
            height: calc(58px + (100vw - 320px) * 0.00562);
            background: #0054A6;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .departments-map-interface-slider-slide-btn__icon {
              height: 33px;
              width: 33px;
              fill: #fff; }
          .departments-map-interface-slider-slide__obl {
            font-size: 15px;
            line-height: 20px;
            color: #878787; }
            @media (max-width: 1170px) {
              .departments-map-interface-slider-slide__obl {
                font-size: 13px; } }
          .departments-map-interface-slider-slide__title {
            display: inline-block;
            margin-top: 2px;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding-right: 30px;
            color: #333333; }
            @media (max-width: 1170px) {
              .departments-map-interface-slider-slide__title {
                margin-top: 3px;
                font-size: 14px;
                line-height: 20px; } }
          .departments-map-interface-slider-slide__phone {
            margin-top: 10px;
            padding: 4px 0;
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #0054A6;
            text-decoration-line: underline; }
            @media (max-width: 1170px) {
              .departments-map-interface-slider-slide__phone {
                font-size: 14px;
                line-height: 20px;
                margin-top: 4px; } }
            .departments-map-interface-slider-slide__phone:hover {
              color: #ED1847; }
          .departments-map-interface-slider-slide__time {
            margin-top: 12px;
            max-width: 194px;
            font-size: 16px;
            line-height: 24px;
            color: #333; }
            @media (max-width: 1170px) {
              .departments-map-interface-slider-slide__time {
                margin-top: 8px;
                font-size: 14px;
                line-height: 20px; } }
          .departments-map-interface-slider-slide.slick-current .departments-map-interface-slider-slide-inner {
            box-shadow: 0 7px 4px rgba(0, 0, 0, 0.15);
            border: transparent; }
          .departments-map-interface-slider-slide.slick-current .departments-map-interface-slider-slide-geo {
            background: #ED1847;
            border-radius: 0 0 0 3px; }
            .departments-map-interface-slider-slide.slick-current .departments-map-interface-slider-slide-geo__icon {
              fill: #fff !important; }
        .departments-map-interface-slider-arrow {
          width: calc(24.75px + (100vw - 320px) * 0.01453);
          height: calc(24.75px + (100vw - 320px) * 0.01453);
          bottom: 20px;
          cursor: pointer;
          transform: rotate(-45deg);
          background: #0054A6;
          z-index: 1;
          box-shadow: 11px 12px 30px rgba(14, 16, 30, 0.08);
          transition: box-shadow .3s, background-color .3s;
          border: none;
          appearance: none;
          display: flex;
          align-items: center;
          justify-content: center; }
          .departments-map-interface-slider-arrows {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0 10px; }
          .departments-map-interface-slider-arrow.slick-hidden {
            display: none; }
          .departments-map-interface-slider-arrow.prev {
            transform: rotate(135deg); }
          .departments-map-interface-slider-arrow__icon {
            width: calc(15.47px + (100vw - 320px) * 0.00908);
            height: calc(15.47px + (100vw - 320px) * 0.00908);
            transform: rotate(-45deg);
            fill: #fff;
            transition: fill .3s; }
          .departments-map-interface-slider-arrow:hover {
            box-shadow: none;
            background: #ED1847; }
            .departments-map-interface-slider-arrow:hover .departments-map-interface-slider-arrow__icon {
              fill: #fff; }
      @media (max-width: 1170px) {
        .departments-map-interface {
          flex-direction: column-reverse; } }
  .departments__title {
    font-weight: 900;
    font-size: calc(28px + (100vw - 768px) * 0.00608);
    padding: 0 15px;
    color: #333;
    position: relative;
    z-index: 2;
    text-align: center;
    background: #fff;
    line-height: calc(36px + (100vw - 768px) * 0.00174); }
    @media (max-width: 768px) {
      .departments__title {
        max-width: 640px;
        font-size: calc(20px + (100vw - 320px) * 0.01786);
        line-height: calc(26px + (100vw - 320px) * 0.02232); } }
    .departments__title > b {
      color: #0054A6; }
  .departments__description {
    margin-top: calc(16px + (100vw - 320px) * 0.0025);
    padding: 0 15px;
    font-size: calc(16px + (100vw - 768px) * 0.00087);
    line-height: calc(24px + (100vw - 768px) * 0.00174);
    text-align: center;
    color: #878787; }
    @media (max-width: 768px) {
      .departments__description {
        font-size: calc(14px + (100vw - 320px) * 0.00446);
        line-height: calc(21px + (100vw - 320px) * 0.0067); } }

.form-group {
  width: 100%;
  margin-top: calc(10px + (100vw - 320px) * 0.00187); }
  .form-group__textarea {
    resize: vertical;
    height: auto;
    padding-top: 10px;
    min-height: 110px;
    max-height: 350px; }
  .form-group__input {
    font-size: calc(12px + (100vw - 320px) * 0.0025);
    line-height: calc(22px + (100vw - 320px) * 0.00125);
    color: #333;
    padding-left: 20px;
    height: calc(40px + (100vw - 320px) * 0.00813);
    width: 100%;
    border: 1px solid #CDCDCD;
    border-radius: 3px;
    background: #fff; }
    .form-group__input::placeholder {
      color: #878787;
      font-size: calc(12px + (100vw - 320px) * 0.0025);
      line-height: calc(22px + (100vw - 320px) * 0.00125); }
  .form-group:first-child {
    margin-top: 0; }

.news {
  width: 100%;
  padding: calc(60px + (100vw - 320px) * 0.0125) 0 calc(40px + (100vw - 320px) * 0.0375);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  @media (max-width: 1600px) {
    .news {
      padding: calc(60px + (100vw - 320px) * 0.0125) 15px calc(40px + (100vw - 320px) * 0.0375); } }
  .news.p0 {
    padding: 0; }
    @media (max-width: 1600px) {
      .news.p0 {
        padding: 0 15px; } }
  .news-bauble {
    height: 416px;
    border: 1px dashed #878787;
    border-right: none;
    width: 50%;
    position: absolute;
    top: calc(85px + (100vw - 1170px) * 0.02);
    left: calc(5px + (100vw - 1170px) * 0.14667); }
    @media (max-width: 1170px) {
      .news-bauble {
        border: none;
        border-top: 1px dashed #878787;
        top: 32px;
        left: 0;
        width: 100%; } }
    .news-bauble-inner {
      width: 100%;
      height: 100%;
      position: relative; }
    .news-bauble__play {
      width: 35px;
      height: 35px;
      margin-top: -17.5px;
      margin-left: 70%;
      fill: none; }
      .news-bauble__play.typical {
        transform: rotate(55deg); }
      @media (max-width: 1170px) {
        .news-bauble__play {
          margin-left: 30px;
          transform: rotate(180deg); } }
  .news-item {
    width: 345px;
    border-radius: 3px;
    overflow: hidden;
    display: block;
    border: 1px solid #CDCDCD;
    height: 460px;
    transition: border .3s, box-shadow .3s;
    margin: 0 15px; }
    @media (max-width: 600px) {
      .news-item {
        width: 290px; } }
    @media (max-width: 600px) {
      .news-item {
        height: 439px; } }
    .news-item-top {
      position: relative;
      height: 209px;
      width: 100%;
      background: #EBF2F8;
      transition: background-color .3s; }
      @media (max-width: 600px) {
        .news-item-top {
          height: 183px; } }
    .news-item__date {
      position: absolute;
      top: 23px;
      left: 27px;
      font-style: italic;
      font-size: 18px;
      line-height: 24px;
      color: #0054A6; }
      @media (max-width: 1170px) {
        .news-item__date {
          font-size: 15px;
          line-height: 22px;
          left: 20px; } }
    .news-item__title {
      position: absolute;
      left: 27px;
      bottom: 29px;
      z-index: 2;
      font-weight: bold;
      font-size: 20px;
      text-decoration-line: underline;
      color: #0054A6;
      line-height: 26px;
      max-width: 250px; }
      @media (max-width: 1170px) {
        .news-item__title {
          font-size: 18px;
          line-height: 20px;
          left: 20px; } }
    .news-item__date, .news-item__title {
      transition: color .3s; }
    .news-item__text {
      font-size: 16px;
      line-height: 24px;
      color: #333;
      padding: 20px 26px 20px 27px; }
      @media (max-width: 600px) {
        .news-item__text {
          font-size: 14px;
          line-height: 21px;
          padding: 16px 20px 51px; } }
    .news-item__icon {
      position: absolute;
      top: 2px;
      right: 1px;
      height: 74px;
      width: 194px;
      fill: #DFE9F3;
      transition: fill .3s; }
      @media (max-width: 600px) {
        .news-item__icon {
          height: 61px;
          width: 161px; } }
    .news-item:hover {
      box-shadow: 0 18px 35px rgba(0, 0, 0, 0.06), 0 17px 34px rgba(0, 0, 0, 0.04);
      border: 1px solid transparent; }
      .news-item:hover .news-item-top {
        background-color: #ED1847; }
      .news-item:hover .news-item__date, .news-item:hover .news-item__title {
        color: #fff; }
      .news-item:hover .news-item__icon {
        fill: #F03B63; }
  .news-items {
    margin-top: 50px;
    z-index: 1;
    margin-left: 15px;
    overflow-x: hidden;
    padding-bottom: calc(90px + (100vw - 768px) * -0.02604);
    background-color: #fff;
    display: flex;
    position: relative;
    width: 1490px; }
    @media (max-width: 768px) {
      .news-items {
        padding-bottom: 45px; } }
    .news-items.typical {
      background: #F9F9F9; }
    @media (max-width: 1170px) {
      .news-items {
        margin-top: 25px; } }
    @media (max-width: 1600px) {
      .news-items {
        width: 100%; } }
    .news-items .slick-list {
      width: 100%;
      overflow: unset !important; }
    .news-items .slick-track {
      display: flex; }
    .news-items .slick-dots {
      position: absolute;
      top: calc(100% - 30px);
      width: 100%;
      display: flex;
      justify-content: center;
      margin-right: -11px; }
      @media (max-width: 480px) {
        .news-items .slick-dots {
          top: calc(100% - 64px);
          width: 290px; } }
      .news-items .slick-dots > li {
        height: 6px;
        width: 6px;
        marker: none;
        margin-right: 11px;
        display: flex; }
        .news-items .slick-dots > li:first-child:last-child {
          display: none; }
        .news-items .slick-dots > li > button {
          width: 100%;
          height: 100%;
          border: none;
          appearance: none;
          border-radius: 50%;
          background: #CDCDCD;
          font-size: 0; }
        .news-items .slick-dots > li.slick-active > button {
          background: #ED1847; }
  .news__title {
    font-weight: 900;
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding: 0 17px;
    font-size: calc(20px + (100vw - 320px) * 0.00937);
    line-height: 40px;
    color: #333; }
    .news__title.typical {
      background: #F9F9F9; }
    @media (max-width: 1170px) {
      .news__title {
        line-height: 26px; } }
  .news__btn {
    width: calc(186px + (100vw - 320px) * 0.02625);
    font-weight: 900;
    font-size: 16px;
    z-index: 1;
    line-height: 24px;
    height: calc(40px + (100vw - 320px) * 0.00813); }
    @media (max-width: 768px) {
      .news__btn {
        margin-top: 10px; } }
  @media (max-width: 768px) and (max-width: 480px) {
    .news__btn {
      margin-top: -24px; } }
    @media (max-width: 1170px) {
      .news__btn {
        font-size: 11px;
        line-height: 15px; } }

.question {
  width: 100%;
  padding: 30px 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0054A6; }
  @media (max-width: 1600px) {
    .question {
      padding: 30px 15px;
      justify-content: center;
      flex-flow: row wrap; } }
  @media (max-width: 1600px) {
    .question-content {
      width: 100%; } }
  .question-form {
    margin-left: 54px;
    height: 100%;
    display: flex;
    align-items: center; }
    @media (max-width: 1600px) {
      .question-form {
        width: 600px;
        margin-left: 0;
        justify-content: center;
        margin-top: 10px;
        flex-flow: row wrap; } }
  @media (max-width: 1600px) and (max-width: 600px) {
    .question-form {
      width: 100%; } }
    .question-form__input {
      border: 1px solid #82ABD3;
      height: calc(40px + (100vw - 320px) * 0.00813);
      background-color: transparent;
      padding-left: 25px;
      color: #fff; }
      .question-form__input:first-child {
        margin-right: 17px; }
        @media (max-width: 600px) {
          .question-form__input:first-child {
            margin-right: 0; } }
      .question-form__input::placeholder {
        color: #82ABD3;
        font-size: 16px;
        line-height: 24px; }
        @media (max-width: 768px) {
          .question-form__input::placeholder {
            font-size: 14px;
            line-height: 22px; } }
      .question-form__input.name {
        width: calc(68.66667px + (100vw - 320px) * 0.21458); }
      .question-form__input.phone {
        width: calc(41px + (100vw - 320px) * 0.12812); }
      @media (max-width: 1600px) {
        .question-form__input {
          padding-left: 20px;
          width: calc(50% - 12px) !important;
          margin-top: 10px; } }
  @media (max-width: 1600px) and (max-width: 600px) {
    .question-form__input {
      width: 100% !important; } }
    .question-form__btn {
      width: 233px;
      height: 53px;
      margin-left: 27px;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px; }
      @media (max-width: 1600px) {
        .question-form__btn {
          margin-top: 16px;
          width: 100%;
          margin-left: 0; } }
  @media (max-width: 1600px) and (max-width: 1170px) {
    .question-form__btn {
      height: 40px;
      font-size: 11px;
      line-height: 15px; } }
  .question-personal, .question-personal__link {
    font-size: 14px;
    line-height: 20px;
    color: #82ABD3; }
    @media (max-width: 1170px) {
      .question-personal, .question-personal__link {
        font-size: 12px;
        line-height: 17px; } }
  .question-personal {
    margin-left: 24px; }
    @media (max-width: 1600px) {
      .question-personal {
        width: 100%;
        margin-left: 0;
        margin-top: 11px;
        text-align: center; } }
  .question-personal__link {
    text-decoration: underline; }
  .question__title {
    font-weight: 900;
    font-size: calc(18px + (100vw - 320px) * 0.00375);
    line-height: calc(20px + (100vw - 320px) * 0.00375);
    color: #fff; }
  .question__text {
    margin-top: 13px;
    font-size: 16px;
    line-height: 24px;
    color: #fff; }
    @media (max-width: 1170px) {
      .question__text {
        font-size: 14px;
        line-height: 21px; } }
  @media (max-width: 1600px) {
    .question__title, .question__text {
      text-align: center; }
    .question__text {
      margin-top: 11px; } }

.typical-advantages-item:hover {
  color: #0054A6 !important; }

.text-form {
  margin: 24px 0 16px !important; }

.content-text {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -16px; }
  .content-text h1, .content-text h2, .content-text h3, .content-text h4, .content-text caption {
    font-weight: bold;
    color: #333;
    width: 100%; }
  .content-text h2, .content-text h3, .content-text h4 {
    margin-top: 24px; }
  .content-text p {
    color: #333;
    font-size: calc(14px + (100vw - 320px) * 0.00125);
    line-height: calc(21px + (100vw - 320px) * 0.00187);
    margin-bottom: 16px;
    width: 100%; }
  .content-text span {
    color: #333;
    font-size: calc(14px + (100vw - 320px) * 0.00125);
    line-height: calc(21px + (100vw - 320px) * 0.00187); }
  .content-text img {
    width: 100% !important;
    object-fit: contain; }
  .content-text h1 {
    margin-bottom: 28px;
    font-size: 38px !important;
    line-height: 43px !important; }
    @media (max-width: 1170px) {
      .content-text h1 {
        font-size: calc(28.5px + (100vw - 320px) * 0.00594) !important;
        line-height: calc(32.25px + (100vw - 320px) * 0.00672) !important; } }
  .content-text h2 {
    font-size: 31px !important;
    line-height: 40px !important;
    margin-bottom: 24px; }
    @media (max-width: 1170px) {
      .content-text h2 {
        font-size: calc(23.25px + (100vw - 320px) * 0.00484) !important;
        line-height: calc(30px + (100vw - 320px) * 0.00625) !important; } }
  .content-text h3 {
    font-size: 28px !important;
    line-height: 36px !important;
    margin-bottom: 20px; }
    @media (max-width: 1170px) {
      .content-text h3 {
        font-size: calc(21px + (100vw - 320px) * 0.00438) !important;
        line-height: calc(27px + (100vw - 320px) * 0.00562) !important; } }
  .content-text h4 {
    font-size: 28px !important;
    line-height: 36px !important;
    margin-bottom: 20px; }
    @media (max-width: 1170px) {
      .content-text h4 {
        font-size: calc(21px + (100vw - 320px) * 0.00438) !important;
        line-height: calc(27px + (100vw - 320px) * 0.00562) !important; } }
  .content-text ol, .content-text ul {
    padding-left: 20px;
    margin-bottom: 16px;
    width: 100%; }
    .content-text ol > li, .content-text ul > li {
      width: 100%;
      margin-top: 12px;
      font-size: calc(14px + (100vw - 320px) * 0.00125);
      line-height: calc(19px + (100vw - 320px) * 0.00187); }
      .content-text ol > li:first-child, .content-text ul > li:first-child {
        margin-top: 0; }
  .content-text ol > li {
    list-style-type: decimal; }
  .content-text ul > li {
    position: relative;
    list-style-type: none;
    padding-left: 9px; }
    .content-text ul > li:before {
      left: -20px;
      top: 0;
      width: 20px;
      height: 20px;
      content: "";
      position: absolute;
      background-image: url("/static/images/check.svg");
      background-size: 19.21px 15.24px;
      background-repeat: no-repeat;
      background-position-y: 3.38px; }
  .content-text table {
    margin: 24px 0 16px;
    width: 100%;
    max-width: 100%; }
    @media (max-width: 768px) {
      .content-text table {
        overflow: scroll; } }
    .content-text table tbody, .content-text table thead {
      display: block;
      min-width: 600px; }
    .content-text table > caption {
      font-size: 24px !important;
      line-height: 28px !important;
      margin-bottom: 18px;
      text-align: left; }
      @media (max-width: 1170px) {
        .content-text table > caption {
          font-size: calc(18px + (100vw - 320px) * 0.00375) !important;
          line-height: calc(21px + (100vw - 320px) * 0.00438) !important; } }
    .content-text table tr {
      display: flex;
      flex-flow: row nowrap;
      width: 100%; }
      .content-text table tr:first-child td {
        border-top: 2px solid #ED1847; }
    .content-text table td, .content-text table th {
      padding: 20px;
      display: flex;
      vertical-align: middle;
      flex: 1;
      font-weight: normal;
      font-size: calc(14px + (100vw - 320px) * 0.00125);
      line-height: calc(21px + (100vw - 320px) * 0.00187);
      align-items: center;
      margin: -1px 0 0 -1px;
      border: 1px solid #D5E4F3; }
  .content-text a {
    color: #EB5757 !important;
    text-decoration: underline; }
    .content-text a:hover {
      color: #EB5757 !important;
      text-decoration: none; }
    .content-text a:visited {
      color: #7A33D4 !important;
      text-decoration: underline; }

.text_underline {
  margin: 24px 0 16px !important;
  border: 1px solid #0054A6;
  border-radius: 3px;
  width: 100%;
  padding: 35px 55px;
  background: #EBF2F8; }
  .text_underline > p {
    color: #0054A6; }

.file {
  width: calc(100% / 4 - 30px);
  margin: 15px;
  background: #EBF2F8;
  border-radius: 3px;
  padding: 20px 25px 25px;
  transition: background-color .3s ease-in-out; }
  .files {
    margin: 9px -15px 1px;
    display: flex;
    flex-flow: row wrap; }
  @media (max-width: 1170px) {
    .file {
      width: calc(100% / 3 - 30px); } }
  @media (max-width: 1170px) and (max-width: 768px) {
    .file {
      width: calc(100% / 2 - 30px); } }
  @media (max-width: 1170px) and (max-width: 768px) and (max-width: 600px) {
    .file {
      width: 100%; } }
  .file-info {
    margin-top: 25px;
    display: flex; }
    .file-info-icon__svg {
      display: flex;
      width: 23.5px;
      fill: none;
      height: 23.5px;
      margin-right: 15px; }
      .file-info-icon__svg:last-child {
        display: none; }
    .file-info__details {
      font-size: 16px;
      line-height: 23px;
      color: #878787;
      transition: color .3s ease-in-out; }
  .file__title {
    font-size: 16px;
    line-height: 23px;
    color: #0054A6;
    transition: color .3s ease-in-out; }
  .file:hover {
    background: #0054A6; }
    .file:hover .file-info-icon__svg:first-child {
      display: none; }
    .file:hover .file-info-icon__svg:last-child {
      display: flex; }
    .file:hover .file-info__details {
      color: #fff; }
    .file:hover .file__title {
      color: #fff; }

.gallery {
  margin: 9px -15px 1px;
  display: flex;
  flex-flow: row wrap; }
  .gallery-item {
    margin: 15px;
    display: block; }
    .gallery-item_row2 {
      width: calc(100% / 2 - 30px); }
      @media (max-width: 600px) {
        .gallery-item_row2 {
          width: 100%; } }
      .gallery-item_row2 .gallery-item-img {
        height: calc(250px + (100vw - 320px) * 0.15313); }
    .gallery-item_row3 {
      width: calc(100% / 3 - 30px); }
      @media (max-width: 1170px) {
        .gallery-item_row3 {
          width: calc(100% / 2 - 30px); } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .gallery-item_row3 {
      width: 100%; } }
      .gallery-item_row3 .gallery-item-img {
        height: calc(250px + (100vw - 320px) * 0.03875); }
    .gallery-item_row4 {
      width: calc(100% / 4 - 30px); }
      @media (max-width: 1320px) {
        .gallery-item_row4 {
          width: calc(100% / 3 - 30px); } }
      @media (max-width: 1170px) {
        .gallery-item_row4 {
          width: calc(100% / 2 - 30px); } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .gallery-item_row4 {
      width: 100%; } }
      .gallery-item_row4 .gallery-item-img {
        height: calc(250px + (100vw - 320px) * 0.01875); }
    .gallery-item-img {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 3px; }
      .gallery-item-img__img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .gallery-item-hover {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      width: 100%;
      height: 100%;
      background: rgba(6, 72, 151, 0.7);
      opacity: 0;
      transition: opacity .3s ease-in-out; }
      .gallery-item-hover__zoom {
        height: 40px;
        width: 40px;
        fill: none; }
    .gallery-item:hover .gallery-item-hover {
      opacity: 1; }
    .gallery-item__title {
      margin-top: calc(12px + (100vw - 320px) * 0.00125);
      font-size: calc(12px + (100vw - 320px) * 0.00125);
      line-height: calc(18px + (100vw - 320px) * 0.0025);
      color: #878787; }

.quote {
  padding: 30px;
  border-radius: 0 5px 5px 0;
  margin: 24px 0 16px;
  background: #F2F5F6;
  border-left: 4px solid #ED1847; }
  .quote-description, .quote-text {
    color: #333;
    font-size: calc(14px + (100vw - 320px) * 0.00125);
    line-height: calc(21px + (100vw - 320px) * 0.00187); }
  .quote-description {
    font-weight: 600;
    margin-top: 10px;
    font-style: italic; }

.gallery {
  display: flex;
  flex-flow: row wrap;
  margin: 24px -15px 16px; }
  .gallery > div {
    margin: 0 15px; }
  .gallery__title {
    font-size: 15px !important;
    line-height: 20px !important;
    color: #333;
    margin-top: 12px; }
    @media (max-width: 1170px) {
      .gallery__title {
        font-size: calc(11.25px + (100vw - 320px) * 0.00234) !important;
        line-height: calc(15px + (100vw - 320px) * 0.00313) !important; } }
  .gallery-image {
    width: 100%;
    display: block; }
    .gallery-image > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .gallery-w-2 {
    width: calc(50% - 30px); }
    .gallery-w-2 .gallery-image {
      max-height: 263px;
      height: 263px; }
  .gallery-w-3 {
    width: calc(33% - 30px); }
    .gallery-w-3 .gallery-image {
      max-height: 171px;
      height: 171px; }
  .gallery-w-4 {
    width: calc(25% - 30px); }
  @media (max-width: 600px) {
    .gallery-w-2, .gallery-w-3, .gallery-w-4 {
      width: 100%;
      margin: 24px 15px 16px !important; }
      .gallery-w-2:first-child, .gallery-w-3:first-child, .gallery-w-4:first-child {
        margin: 0 15px 16px !important; } }

.faq {
  display: block;
  margin: 24px 0 16px; }
  .faq-line {
    margin-top: -1px;
    display: block; }
    .faq-line-header {
      display: flex;
      cursor: pointer;
      border: 1px solid #0054A6;
      position: relative;
      align-items: center;
      user-select: none;
      padding: calc(20px + (100vw - 320px) * 0.00594) calc(64px + (100vw - 320px) * 0.01937) calc(20px + (100vw - 320px) * 0.00594) calc(30px + (100vw - 320px) * 0.00625); }
      .faq-line-header-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: calc(64px + (100vw - 320px) * 0.01937);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .faq-line-header-icon__svg {
          height: calc(24px + (100vw - 320px) * 0.00688);
          width: calc(24px + (100vw - 320px) * 0.00688);
          transition: transform .3s;
          fill: #0054A6; }
      .faq-line-header__title {
        color: #0054A6;
        font-weight: 900;
        font-size: calc(17px + (100vw - 320px) * 0.00187);
        line-height: calc(20px + (100vw - 320px) * 0.00375); }
      .faq-line-header:hover {
        border: 1px solid #ED1847;
        position: relative;
        z-index: 1; }
        .faq-line-header:hover .faq-line-header__title {
          color: #ED1847; }
    .faq-line-content {
      display: none;
      padding: calc(10px + (100vw - 320px) * 0.00438) calc(20px + (100vw - 320px) * 0.03) calc(20px + (100vw - 320px) * 0.01625);
      font-size: calc(14px + (100vw - 320px) * 0.00125);
      line-height: calc(18px + (100vw - 320px) * 0.00375);
      color: #333; }
    .faq-line.active .faq-line-header {
      background: #0054A6;
      border-radius: 3px;
      border: 1px solid #0054A6; }
      .faq-line.active .faq-line-header-icon__svg {
        transform: rotateX(180deg);
        fill: #fff; }
      .faq-line.active .faq-line-header__title {
        color: #fff; }
    .faq-line.active .faq-line-content {
      display: block; }

.index-advantages {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  position: relative;
  background: #fff; }
  @media (max-width: 1170px) {
    .index-advantages {
      padding: 30px 30px 40px; } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .index-advantages {
      padding: 30px 15px 40px; } }
  .index-advantages-first-bauble {
    position: absolute;
    top: calc(100% + 22px);
    pointer-events: none;
    left: calc(-203.53125px + (100vw - 1170px) * -0.17396);
    height: calc(293.71875px + (100vw - 1170px) * 0.25104);
    z-index: 2;
    width: calc(301.64062px + (100vw - 1170px) * 0.25781);
    fill: none; }
  .index-advantages-mobile {
    display: none;
    width: 100%;
    z-index: 1;
    margin-top: 30px;
    position: relative;
    border-top: 1px dashed #757575; }
    @media (max-width: 600px) {
      .index-advantages-mobile {
        display: block; } }
    .index-advantages-mobile__icon {
      position: absolute;
      top: -15px;
      right: 27.82px;
      transform: rotate(3deg);
      fill: none;
      height: 30.53px;
      width: 30.53px; }
  .index-advantages-content {
    width: 1444px;
    display: flex;
    padding-top: 20px;
    flex-flow: row wrap; }
    @media (max-width: 1600px) {
      .index-advantages-content {
        width: 1320px;
        padding: 20px 15px 0;
        justify-content: space-between; } }
  @media (max-width: 1600px) and (max-width: 1320px) {
    .index-advantages-content {
      width: 1170px; } }
  @media (max-width: 1600px) and (max-width: 1320px) and (max-width: 1170px) {
    .index-advantages-content {
      padding: 20px 80px 0;
      width: 100%; } }
  @media (max-width: 1600px) and (max-width: 1320px) and (max-width: 1170px) and (max-width: 600px) {
    .index-advantages-content {
      padding: 8px 0 0;
      justify-content: center; } }
  .index-advantages-item {
    margin-top: 30px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-style: italic;
    font-weight: 500;
    font-size: calc(15px + (100vw - 768px) * 0.0026);
    line-height: calc(22px + (100vw - 768px) * 0.00174); }
    .index-advantages-item__icon {
      height: calc(40px + (100vw - 320px) * 0.0225);
      display: flex;
      width: calc(40px + (100vw - 320px) * 0.0225);
      fill: none; }
      .index-advantages-item__icon:last-child {
        display: none; }
    .index-advantages-item:hover .index-advantages-item__icon:last-child {
      display: flex; }
    .index-advantages-item:hover .index-advantages-item__icon:first-child {
      display: none; }
    @media (max-width: 1170px) {
      .index-advantages-item__icon:last-child {
        display: flex; }
      .index-advantages-item__icon:first-child {
        display: none; } }
    .index-advantages-item__repair {
      max-width: 292px;
      margin-right: 189px; }
      .index-advantages-item__repair .index-advantages-item__icon {
        margin-right: calc(19px + (100vw - 320px) * 0.01438); }
    .index-advantages-item__cost {
      margin-right: 162px;
      max-width: 261px; }
      .index-advantages-item__cost .index-advantages-item__icon {
        margin-right: calc(19px + (100vw - 320px) * 0.00625); }
    .index-advantages-item__cert {
      max-width: 359px; }
      .index-advantages-item__cert .index-advantages-item__icon {
        margin-right: calc(19px + (100vw - 320px) * 0.00668); }
    .index-advantages-item__guar {
      margin-left: 240px;
      max-width: 235px; }
      .index-advantages-item__guar .index-advantages-item__icon {
        margin-right: calc(19px + (100vw - 320px) * 0.01125); }
    .index-advantages-item__time {
      margin-left: 175px;
      max-width: 291px; }
      .index-advantages-item__time .index-advantages-item__icon {
        margin-right: calc(19px + (100vw - 320px) * 0.00438); }
    .index-advantages-item__stock {
      margin-left: 209px;
      max-width: 293px; }
      .index-advantages-item__stock .index-advantages-item__icon {
        margin-right: calc(19px + (100vw - 320px) * 0.00187); }
    @media (max-width: 1600px) {
      .index-advantages-item {
        width: calc(100% / 3 - 60px); }
        .index-advantages-item__repair, .index-advantages-item__cost, .index-advantages-item__cert, .index-advantages-item__guar, .index-advantages-item__time, .index-advantages-item__stock {
          max-width: unset;
          margin: 30px 0 0 0; } }
  @media (max-width: 1600px) and (max-width: 1170px) {
    .index-advantages-item {
      width: calc(100% / 2 - 60px); } }
  @media (max-width: 1600px) and (max-width: 1170px) and (max-width: 979px) {
    .index-advantages-item {
      width: calc(100% / 2 - 45px); } }
  @media (max-width: 1600px) and (max-width: 1170px) and (max-width: 979px) and (max-width: 600px) {
    .index-advantages-item {
      width: 222px;
      max-width: 222px; } }
  .index-advantages__title {
    font-weight: 900;
    font-size: calc(28px + (100vw - 768px) * 0.00608);
    line-height: calc(36px + (100vw - 768px) * 0.00174);
    color: #333; }
    @media (max-width: 768px) {
      .index-advantages__title {
        font-size: calc(20px + (100vw - 320px) * 0.01786);
        line-height: calc(26px + (100vw - 320px) * 0.02232);
        text-align: center; } }

.index-services {
  padding: calc(40px + (100vw - 320px) * 0.025) 0;
  background: #F9F9F9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1170px) {
    .index-services {
      padding: calc(40px + (100vw - 320px) * 0.025) 30px; } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .index-services {
      padding: calc(40px + (100vw - 320px) * 0.025) 15px; } }
  .index-services-first-bauble {
    position: absolute;
    top: calc(31.07812px + (100vw - 1170px) * 0.02656);
    left: calc(41.4375px + (100vw - 1170px) * 0.03542);
    width: calc(74.34375px + (100vw - 1170px) * 0.06354);
    height: calc(74.34375px + (100vw - 1170px) * 0.06354);
    fill: none; }
  .index-services-wrapper {
    width: 1444px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1600px) {
      .index-services-wrapper {
        width: 1290px; } }
  @media (max-width: 1600px) and (max-width: 1320px) {
    .index-services-wrapper {
      width: 1140px; } }
  @media (max-width: 1600px) and (max-width: 1320px) and (max-width: 1170px) {
    .index-services-wrapper {
      width: 100%; } }
  .index-services-list {
    margin-top: calc(40px + (100vw - 768px) * 0.02431);
    width: 100%; }
    @media (max-width: 768px) {
      .index-services-list {
        margin-top: 40px; } }
    .index-services-list-top, .index-services-list-bottom {
      display: flex; }
      @media (max-width: 1600px) {
        .index-services-list-top, .index-services-list-bottom {
          flex-flow: row wrap; } }
      .index-services-list-top__img, .index-services-list-bottom__img {
        width: 690px;
        height: calc(435px + (100vw - 768px) * -0.01042);
        margin: -27.5px 0;
        border-radius: 3px;
        object-fit: cover; }
        @media (max-width: 768px) {
          .index-services-list-top__img, .index-services-list-bottom__img {
            height: calc(178px + (100vw - 320px) * 0.57366); } }
        @media (max-width: 1600px) {
          .index-services-list-top__img, .index-services-list-bottom__img {
            width: 100%;
            margin: 0;
            border-radius: 3px 3px 0 0; } }
      .index-services-list-top-content, .index-services-list-bottom-content {
        filter: drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.08));
        background: #FFFFFF; }
    .index-services-list-top-content {
      padding: calc(28px + (100vw - 768px) * 0.01042) calc(23px + (100vw - 768px) * 0.05816) calc(27px + (100vw - 768px) * 0.01128) calc(23px + (100vw - 768px) * 0.04948);
      clip-path: polygon(0 0, calc(100% - 60px) 0, 100% 60px, 100% 80%, 100% 100%, 20% 100%, 0 100%, 0% 20%); }
      @media (max-width: 768px) {
        .index-services-list-top-content {
          padding: calc(25px + (100vw - 320px) * 0.0067) calc(15px + (100vw - 320px) * 0.01786) calc(24px + (100vw - 320px) * 0.0067) calc(15px + (100vw - 320px) * 0.01786); } }
      @media (max-width: 1600px) {
        .index-services-list-top-content {
          clip-path: unset; } }
    @media (max-width: 1170px) {
      .index-services-list-top {
        position: relative; } }
    .index-services-list-bottom {
      margin-top: calc(66px + (100vw - 320px) * 0.04813); }
      @media (max-width: 1600px) {
        .index-services-list-bottom {
          flex-flow: column-reverse; } }
      .index-services-list-bottom-content {
        padding: calc(28px + (100vw - 768px) * 0.01042) calc(23px + (100vw - 768px) * 0.05816) calc(27px + (100vw - 768px) * 0.01128) calc(23px + (100vw - 768px) * 0.04948);
        clip-path: polygon(60px 0, 100% 0, 100% 0, 100% 80%, 100% 100%, 20% 100%, 0 100%, 0% 60px); }
        @media (max-width: 768px) {
          .index-services-list-bottom-content {
            padding: calc(25px + (100vw - 320px) * 0.0067) calc(15px + (100vw - 320px) * 0.01786) calc(24px + (100vw - 320px) * 0.0067) calc(15px + (100vw - 320px) * 0.01786); } }
        @media (max-width: 1600px) {
          .index-services-list-bottom-content {
            clip-path: unset; } }
    .index-services-list-btn {
      margin-top: calc(24px + (100vw - 320px) * 0.00375);
      width: 238px;
      height: 53px;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px; }
      @media (max-width: 768px) {
        .index-services-list-btn {
          width: calc(140px + (100vw - 320px) * 0.21875); } }
      @media (max-width: 768px) {
        .index-services-list-btn {
          font-size: calc(11px + (100vw - 320px) * 0.01116);
          line-height: calc(15px + (100vw - 320px) * 0.02009);
          height: calc(40px + (100vw - 320px) * 0.02902); } }
      .index-services-list-btn-red {
        cursor: pointer;
        width: 238px;
        margin-top: calc(24px + (100vw - 320px) * 0.00375);
        background-color: transparent;
        border: 1px solid #ED1847;
        height: 53px;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        color: #ED1847;
        transition: border .3s, color .3s; }
        @media (max-width: 768px) {
          .index-services-list-btn-red {
            width: calc(140px + (100vw - 320px) * 0.21875); } }
        @media (max-width: 768px) {
          .index-services-list-btn-red {
            font-size: calc(11px + (100vw - 320px) * 0.01116);
            line-height: calc(15px + (100vw - 320px) * 0.02009);
            height: calc(40px + (100vw - 320px) * 0.02902); } }
        .index-services-list-btn-red:hover {
          border: 1px solid #0054A6;
          color: #0054A6; }
    .index-services-list__title {
      font-weight: 900;
      font-size: calc(24px + (100vw - 768px) * 0.00347);
      line-height: calc(30px + (100vw - 768px) * 0.00521);
      color: #333; }
      @media (max-width: 768px) {
        .index-services-list__title {
          font-size: calc(18px + (100vw - 320px) * 0.01339);
          line-height: calc(22px + (100vw - 320px) * 0.01786); } }
    .index-services-list__text {
      margin-top: calc(15px + (100vw - 768px) * -0.00087);
      font-size: calc(15px + (100vw - 768px) * 0.00174);
      line-height: calc(21px + (100vw - 768px) * 0.00434);
      color: #333; }
      @media (max-width: 768px) {
        .index-services-list__text {
          margin-top: calc(10px + (100vw - 320px) * 0.01116);
          font-size: calc(13px + (100vw - 320px) * 0.00446);
          line-height: calc(19px + (100vw - 320px) * 0.00446); } }
  .index-services-voldemort {
    width: 1582px;
    height: 332px;
    position: relative;
    margin-top: calc(30px + (100vw - 768px) * 0.06858);
    overflow: hidden;
    border-radius: 3px;
    z-index: 1; }
    @media (max-width: 768px) {
      .index-services-voldemort {
        margin-top: calc(40px + (100vw - 320px) * -0.02232); } }
    @media (max-width: 1600px) {
      .index-services-voldemort {
        width: 1290px; } }
  @media (max-width: 1600px) and (max-width: 1320px) {
    .index-services-voldemort {
      width: 1140px; } }
  @media (max-width: 1600px) and (max-width: 1320px) and (max-width: 1170px) {
    .index-services-voldemort {
      width: 100%;
      height: 543px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between; } }
    .index-services-voldemort-content {
      padding: calc(40px + (100vw - 320px) * 0.00187) calc(25px + (100vw - 320px) * 0.05937) calc(6.46px + (100vw - 320px) * 0.03034);
      width: 785px; }
      @media (max-width: 1170px) {
        .index-services-voldemort-content {
          width: 100%;
          position: absolute;
          top: 0; } }
  @media (max-width: 1170px) and (max-width: 768px) {
    .index-services-voldemort-content {
      max-width: 623px; } }
    .index-services-voldemort__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1; }
      @media (max-width: 1170px) {
        .index-services-voldemort__bg {
          object-fit: fill; } }
    .index-services-voldemort__img {
      position: absolute;
      z-index: -1;
      width: 888px;
      height: 269px;
      object-fit: cover;
      left: 621px;
      top: 32px; }
      @media (max-width: 1170px) {
        .index-services-voldemort__img {
          bottom: unset;
          position: absolute;
          top: 10px;
          width: calc(100% - 70px);
          margin-top: 35px;
          object-fit: contain;
          height: calc(100% - 35px);
          left: unset; } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .index-services-voldemort__img {
      width: 100%;
      height: 275px;
      top: unset;
      bottom: 10px; } }
    .index-services-voldemort__bg.--medium, .index-services-voldemort__img.--medium {
      display: none; }
      @media (max-width: 1170px) {
        .index-services-voldemort__bg.--medium, .index-services-voldemort__img.--medium {
          display: block; } }
    .index-services-voldemort__bg.--mini, .index-services-voldemort__img.--mini {
      display: none; }
      @media (max-width: 600px) {
        .index-services-voldemort__bg.--mini, .index-services-voldemort__img.--mini {
          display: block; } }
    .index-services-voldemort__header {
      font-weight: 900;
      font-size: calc(17px + (100vw - 320px) * 0.00802);
      text-transform: uppercase;
      line-height: calc(21px + (100vw - 320px) * 0.01);
      color: #fff; }
      .index-services-voldemort__header.big {
        margin-top: -4px;
        font-weight: 900;
        font-size: calc(35px + (100vw - 320px) * 0.01723);
        line-height: calc(44px + (100vw - 320px) * 0.02063); }
    .index-services-voldemort__description {
      margin-top: calc(18.73px + (100vw - 320px) * -0.00402);
      font-size: calc(13px + (100vw - 320px) * 0.00313);
      line-height: calc(19px + (100vw - 320px) * 0.00562);
      color: #BAD1E7; }
      .index-services-voldemort__description > u {
        color: #fff; }
    .index-services-voldemort__label {
      color: #BAD1E7;
      font-size: 12px;
      line-height: 28px; }
    .index-services-voldemort b {
      color: #FFF; }
  .index-services__title {
    font-weight: 900;
    font-size: calc(28px + (100vw - 768px) * 0.00608);
    line-height: calc(36px + (100vw - 768px) * 0.00174);
    text-align: center;
    color: #333; }
    @media (max-width: 768px) {
      .index-services__title {
        font-size: calc(20px + (100vw - 320px) * 0.01786);
        line-height: calc(26px + (100vw - 320px) * 0.02232); } }
    @media (max-width: 1600px) {
      .index-services__title {
        width: 100%; } }
    .index-services__title > u.active {
      position: relative; }
      .index-services__title > u.active:after {
        content: "";
        position: absolute;
        top: 0;
        right: -3px;
        width: 3px;
        height: 100%;
        background: #333; }
  .index-services-bauble {
    position: absolute;
    top: 11px;
    width: calc(1170px + (100vw - 1170px) * 0.792);
    height: 685px;
    border-bottom: 1px dashed #878787;
    border-right: 1px dashed #878787;
    left: calc(-300px + (100vw - 768px) * 0.26042);
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 1800px) {
      .index-services-bauble {
        width: 100%; } }
    @media (max-width: 1600px) {
      .index-services-bauble {
        height: calc(1000px + (100vw - 1170px) * 0.02326); } }
  @media (max-width: 1600px) and (max-width: 1170px) {
    .index-services-bauble {
      height: 100%;
      left: -30px;
      width: calc(100% + 80px);
      top: calc(33px + (100vw - 320px) * 0.02412); } }
    .index-services-bauble__icon {
      width: 34.45px;
      height: 34.45px;
      margin-right: -17.5px;
      margin-bottom: -17.225px;
      transform: rotate(-90deg);
      fill: none; }
  .index-services__description {
    margin-top: calc(18px + (100vw - 768px) * 0.00174);
    width: 1324px;
    text-align: center;
    font-size: calc(16px + (100vw - 768px) * 0.00087);
    line-height: calc(24px + (100vw - 768px) * 0.00174);
    color: #878787; }
    @media (max-width: 1600px) {
      .index-services__description {
        width: 100%; } }
    @media (max-width: 1170px) {
      .index-services__description {
        text-align: unset; } }
    @media (max-width: 768px) {
      .index-services__description {
        margin-top: calc(25px + (100vw - 320px) * -0.01562);
        font-size: calc(14px + (100vw - 320px) * 0.00446);
        line-height: calc(21px + (100vw - 320px) * 0.0067); } }
    .index-services__description > b {
      color: #ED1847; }

.index-additional {
  margin-top: calc(-265px + (100vw - 320px) * -0.0125);
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center; }
  @media (max-width: 1170px) {
    .index-additional {
      margin-top: calc((-60px + (100vw - 320px) * -0.0125) + 20px); } }
  .index-additional-second-bauble {
    position: absolute;
    top: calc(230.34375px + (100vw - 1170px) * 0.19687);
    left: calc(14.625px + (100vw - 1170px) * 0.0125);
    width: calc(58.5px + (100vw - 1170px) * 0.05);
    height: calc(58.5px + (100vw - 1170px) * 0.05);
    fill: none; }
    @media (max-width: 1600px) {
      .index-additional-second-bauble {
        top: 140px; } }
  .index-additional-first-bauble {
    position: absolute;
    top: calc(67.03125px + (100vw - 1170px) * 0.05729);
    pointer-events: none;
    left: calc(-74.95312px + (100vw - 1170px) * -0.06406);
    height: calc(145.64062px + (100vw - 1170px) * 0.12448);
    z-index: 1;
    width: calc(149.29688px + (100vw - 1170px) * 0.1276);
    fill: none; }
    @media (max-width: 1600px) {
      .index-additional-first-bauble {
        top: -70px; } }
  .index-additional-wrapper {
    width: calc(895.78125px + (100vw - 1170px) * 0.76562);
    position: relative;
    padding-bottom: 80px; }
    @media (max-width: 1600px) {
      .index-additional-wrapper {
        width: 100%;
        padding-right: 30px;
        margin-left: 30px; } }
  @media (max-width: 1600px) and (max-width: 600px) {
    .index-additional-wrapper {
      padding-right: 15px;
      margin-left: 15px; } }
    .index-additional-wrapper-inner {
      margin-top: 281px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      @media (max-width: 1170px) {
        .index-additional-wrapper-inner {
          max-width: calc(530px + (100vw - 600px) * 0.32738);
          margin-top: 60px; } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .index-additional-wrapper-inner {
      max-width: unset; } }
  .index-additional-obs {
    position: absolute;
    top: 240px;
    width: calc(480px + (100vw - 1170px) * 0.25333);
    max-width: calc(480px + (100vw - 1170px) * 0.25333);
    left: 0; }
    .index-additional-obs-inner {
      width: 100%;
      position: relative; }
    .index-additional-obs__arrow {
      position: absolute;
      right: -19px;
      top: 50%;
      transform: rotate(270deg);
      height: 35px;
      width: 35px;
      fill: none; }
    @media (max-width: 1170px) {
      .index-additional-obs {
        position: unset;
        width: 100%;
        max-width: unset; } }
    .index-additional-obs__header {
      font-size: 18px;
      line-height: 42px;
      color: #878787; }
      @media (max-width: 1170px) {
        .index-additional-obs__header {
          font-size: 15px;
          line-height: 22px; } }
    .index-additional-obs__title {
      margin-top: -2px;
      font-weight: 900;
      font-size: calc(20px + (100vw - 320px) * 0.00937);
      line-height: calc(26px + (100vw - 320px) * 0.00875);
      color: #333; }
      @media (max-width: 1170px) {
        .index-additional-obs__title {
          margin-top: 3px; } }
      .index-additional-obs__title > b {
        color: #ED1847; }
    .index-additional-obs__description {
      font-size: calc(14px + (100vw - 320px) * 0.00187);
      line-height: calc(21px + (100vw - 320px) * 0.00313);
      color: #878787;
      margin-top: 20px;
      max-width: 594px; }
      @media (max-width: 1170px) {
        .index-additional-obs__description {
          max-width: unset; } }
    .index-additional-obs__btn {
      margin-top: calc(20px + (100vw - 320px) * 0.0125);
      height: calc(40px + (100vw - 320px) * 0.00813);
      width: calc(155px + (100vw - 320px) * 0.05187);
      font-weight: 900;
      font-size: calc(11px + (100vw - 320px) * 0.00313);
      line-height: calc(15px + (100vw - 320px) * 0.00562); }
  .index-additional-item {
    width: calc(600px + (100vw - 1170px) * 0.16);
    border-radius: 3px;
    display: block;
    overflow: hidden;
    transition: background-color .3s, box-shadow .3s;
    background: #EBF2F8;
    margin-top: calc(15px + (100vw - 320px) * 0.00937); }
    @media (max-width: 1170px) {
      .index-additional-item {
        width: 100%; } }
    .index-additional-item:first-child {
      margin-top: 42px; }
    .index-additional-item-content {
      padding: 58px 96px 50px 60px; }
      @media (max-width: 1170px) {
        .index-additional-item-content {
          padding: 57px calc(20px + (100vw - 320px) * 0.02941) calc(22px + (100vw - 320px) * 0.00813); } }
    .index-additional-item-details {
      display: flex;
      align-items: center;
      margin-top: calc(22px + (100vw - 320px) * 0.005); }
      .index-additional-item-details__text {
        font-weight: bold;
        font-size: calc(11px + (100vw - 320px) * 0.00438);
        line-height: calc(15px + (100vw - 320px) * 0.00562);
        padding: 2px 0;
        margin-right: 12px;
        transition: color .3s;
        color: #0054A6; }
      .index-additional-item-details__icon {
        transform: rotate(-90deg);
        height: calc(14px + (100vw - 320px) * 0.00625);
        width: calc(14px + (100vw - 320px) * 0.00625);
        transition: fill .3s;
        fill: #0054A6; }
    .index-additional-item__title {
      position: absolute;
      left: 0;
      bottom: -43px;
      height: 77px;
      transition: background-color .3s;
      width: 526px;
      padding: 0 39px 15px;
      background: #0054A6;
      font-weight: 900;
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 26px;
      color: #FFFFFF;
      mask: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTI1IiBoZWlnaHQ9Ijc1IiB2aWV3Qm94PSIwIDAgNTI1IDc1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICAgIDxwYXRoIGQ9Ik0wIDBINTIyLjE5NUM1MjQuMDM2IDAgNTI1LjMxMSAxLjgzODgzIDUyNC42NjQgMy41NjI3Nkw1MDMuNzcyIDU5LjI4ODdDNTAzLjM4NiA2MC4zMTgxIDUwMi40MDIgNjEgNTAxLjMwMyA2MUgwVjBaIiBmaWxsPSIjRUQxODQ3Ii8+DQogICAgPHBhdGggZD0iTTg3IDU3LjVMNTguNSA3NC4zODc1VjQwLjYxMjVMODcgNTcuNVoiIGZpbGw9IiNFRDE4NDciLz4NCjwvc3ZnPg==);
      mask-size: 100% 100%; }
      @media (max-width: 1170px) {
        .index-additional-item__title {
          font-size: 18px;
          line-height: 24px; } }
  @media (max-width: 1170px) and (max-width: 768px) {
    .index-additional-item__title {
      font-size: calc(14px + (100vw - 320px) * 0.00893);
      line-height: calc(18px + (100vw - 320px) * 0.01339); } }
      @media (max-width: 600px) {
        .index-additional-item__title {
          mask: url("/static/images/clipped_m.svg");
          width: 250px;
          height: 70px;
          display: flex;
          align-items: center;
          padding: 0 5px 12px 10px; } }
    .index-additional-item__description {
      font-size: calc(13px + (100vw - 320px) * 0.00187);
      line-height: calc(19px + (100vw - 320px) * 0.00313);
      min-height: 119px;
      color: #333; }
      @media (max-width: 1170px) {
        .index-additional-item__description {
          min-height: unset; } }
    .index-additional-item-image {
      height: calc(150px + (100vw - 320px) * 0.13875);
      width: 100%;
      position: relative; }
      .index-additional-item-image__img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .index-additional-item:hover {
      background: #fff;
      box-shadow: 0px 18px 35px rgba(0, 0, 0, 0.06), 0px 17px 34px rgba(0, 0, 0, 0.04); }
      .index-additional-item:hover .index-additional-item-details__text {
        color: #ED1847; }
      .index-additional-item:hover .index-additional-item-details__icon {
        fill: #ED1847; }
      .index-additional-item:hover .index-additional-item__title {
        background: #ED1847; }
  .index-additional-line {
    top: 0;
    left: 0;
    position: absolute;
    width: calc(480px + (100vw - 1170px) * 0.25333);
    height: 100%;
    border-right: 1px dashed #878787; }
    @media (max-width: 1170px) {
      .index-additional-line {
        width: calc(100% - calc(15px + (100vw - 600px) * 0.33333)); } }
  @media (max-width: 1170px) and (max-width: 600px) {
    .index-additional-line {
      display: none; } }
    .index-additional-line-inner {
      position: relative;
      height: 100%; }
    .index-additional-line__arrow {
      position: absolute;
      right: -19px;
      top: 151px;
      transform: rotate(270deg);
      height: 35px;
      width: 35px;
      fill: none; }

.index-reviews {
  padding: calc(60px + (100vw - 768px) * 0.01736) calc(15px + (100vw - 768px) * -0.01302);
  position: relative;
  background: rgba(0, 84, 166, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 768px) {
    .index-reviews {
      padding: calc(40px + (100vw - 320px) * 0.04464) 0 calc(40px + (100vw - 320px) * 0.04464) 20px; } }
  @media (max-width: 768px) and (max-width: 600px) {
    .index-reviews {
      padding-left: 0; } }
  .index-reviews-first-bauble {
    position: absolute;
    bottom: calc(-72.51562px + (100vw - 1170px) * -0.06198);
    pointer-events: none;
    right: calc(-159.04688px + (100vw - 1170px) * -0.13594);
    height: calc(237.04688px + (100vw - 1170px) * 0.2026);
    z-index: 1;
    width: calc(243.14062px + (100vw - 1170px) * 0.20781);
    fill: none; }
  .index-reviews-item {
    width: calc((100vw - (100vw - calc(1064px + (100vw - 1170px) * 0.568))) / 2);
    height: calc(512px + (100vw - 320px) * -0.0775);
    padding: 0 10px;
    display: block; }
    @media (max-width: 1170px) {
      .index-reviews-item {
        width: calc((100vw - 30px) / 2);
        padding: 0 15px;
        margin-left: 10px; } }
    @media (max-width: 768px) {
      .index-reviews-item {
        margin-left: 0;
        width: calc(100vw - 40px);
        height: unset; } }
  @media (max-width: 768px) and (max-width: 600px) {
    .index-reviews-item {
      width: 100vw; } }
    .index-reviews-item-inner {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 3px;
      padding: 20px calc(20px + (100vw - 320px) * 0.00438) calc(95px + (100vw - 320px) * 0.01125) calc(20px + (100vw - 320px) * 0.01125);
      position: relative; }
    .index-reviews-item-top {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1170px) {
        .index-reviews-item-top {
          flex-direction: row; } }
      .index-reviews-item-top-left {
        display: flex;
        align-items: center; }
    .index-reviews-item-header {
      display: flex;
      margin-top: 16px;
      align-items: center; }
      @media (max-width: 1170px) {
        .index-reviews-item-header {
          flex-direction: column-reverse;
          align-items: unset; } }
    .index-reviews-item__text {
      margin-top: 16px;
      font-size: calc(14px + (100vw - 320px) * 0.00125);
      line-height: calc(21px + (100vw - 320px) * 0.00187);
      color: #878787; }
      .index-reviews-item__text > b {
        color: #ED1847; }
    .index-reviews-item__btn {
      position: absolute;
      bottom: 30px;
      left: calc(20px + (100vw - 320px) * 0.01125);
      width: 238px;
      height: calc(40px + (100vw - 320px) * 0.00813);
      font-weight: 900;
      font-size: calc(11px + (100vw - 320px) * 0.00313);
      line-height: calc(15px + (100vw - 320px) * 0.00562); }
      @media (max-width: 1170px) {
        .index-reviews-item__btn {
          width: 250px; } }
    .index-reviews-item__name {
      font-weight: 900;
      font-size: 20px;
      line-height: 26px;
      color: #333; }
      @media (max-width: 1170px) {
        .index-reviews-item__name {
          margin-top: 8px;
          font-size: 18px;
          line-height: 20px; } }
    .index-reviews-item__date {
      font-size: 16px;
      line-height: 24px;
      color: #878787;
      margin-left: 10px; }
      @media (max-width: 1170px) {
        .index-reviews-item__date {
          font-size: 15px;
          line-height: 22px;
          margin-left: 0; } }
    .index-reviews-item-icon {
      object-fit: cover;
      margin-right: 13px; }
      @media (max-width: 1170px) {
        .index-reviews-item-icon {
          margin-right: 8px; } }
      .index-reviews-item-icon__icon {
        height: 100%;
        width: 100%; }
        .index-reviews-item-icon__icon:last-child {
          display: none; }
      .index-reviews-item-icon.geo {
        width: 16px;
        height: 27px; }
        @media (max-width: 1170px) {
          .index-reviews-item-icon.geo {
            width: 13px;
            height: 22px;
            margin-right: 13px; } }
      .index-reviews-item-icon.gis {
        width: 32px;
        height: 32px; }
        @media (max-width: 1170px) {
          .index-reviews-item-icon.gis {
            width: 26px;
            height: 26px; } }
      .index-reviews-item-icon.drive {
        width: 60px;
        height: 25px; }
        @media (max-width: 1170px) {
          .index-reviews-item-icon.drive {
            height: 21px;
            width: 49px; } }
      .index-reviews-item-icon.ph {
        width: 154px;
        height: 50.39px; }
        @media (max-width: 1170px) {
          .index-reviews-item-icon.ph {
            width: 108px;
            height: 35.34px; } }
      .index-reviews-item-icon:hover .index-reviews-item-icon__icon:first-child {
        display: none; }
      .index-reviews-item-icon:hover .index-reviews-item-icon__icon:last-child {
        display: block; }
      .index-reviews-item-icon.ph {
        width: 154px;
        height: 50.39px; }
        @media (max-width: 1170px) {
          .index-reviews-item-icon.ph {
            width: 108px;
            height: 35.34px; } }
        .index-reviews-item-icon.ph .index-reviews-item-icon__icon:first-child:last-child {
          display: block !important; }
  .index-reviews-slider {
    width: calc(1170px + (100vw - 1170px) * 0.60933);
    display: flex;
    justify-content: center;
    position: relative; }
    @media (max-width: 1170px) {
      .index-reviews-slider {
        width: 100%; } }
  .index-reviews-arrows {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between; }
    .index-reviews-arrows-prev, .index-reviews-arrows-next {
      width: 48px;
      height: 48px;
      cursor: pointer;
      border: 1px solid #fff;
      display: flex;
      background: none;
      align-items: center;
      justify-content: center; }
      @media (max-width: 1170px) {
        .index-reviews-arrows-prev, .index-reviews-arrows-next {
          display: none; } }
      .index-reviews-arrows-prev__icon, .index-reviews-arrows-next__icon {
        width: 30px;
        height: 30px;
        fill: #fff; }
      .index-reviews-arrows-prev:hover, .index-reviews-arrows-next:hover {
        background: #fff;
        border: 1px solid #fff; }
        .index-reviews-arrows-prev:hover .index-reviews-arrows-prev__icon, .index-reviews-arrows-prev:hover .index-reviews-arrows-next__icon, .index-reviews-arrows-next:hover .index-reviews-arrows-prev__icon, .index-reviews-arrows-next:hover .index-reviews-arrows-next__icon {
          fill: #0054A6; }
    .index-reviews-arrows-prev {
      transform: rotate(45deg); }
      .index-reviews-arrows-prev__icon {
        transform: rotate(45deg); }
    .index-reviews-arrows-next {
      transform: rotate(-45deg); }
      .index-reviews-arrows-next__icon {
        transform: rotate(-45deg); }
  .index-reviews-items {
    width: calc(1064px + (100vw - 1170px) * 0.568);
    overflow: hidden;
    z-index: 2;
    position: relative;
    margin-top: 40px;
    display: flex;
    padding-bottom: 26px; }
    @media (max-width: 1170px) {
      .index-reviews-items {
        width: 100%; } }
    @media (max-width: 600px) {
      .index-reviews-items {
        width: calc(100% + 30px); } }
    @media (max-width: 1170px) {
      .index-reviews-items {
        margin-top: 25px; } }
    .index-reviews-items .slick-dots {
      position: absolute;
      top: calc(100% - 6px);
      width: 100%;
      display: flex;
      justify-content: center;
      margin-right: -11px; }
      @media (max-width: 480px) {
        .index-reviews-items .slick-dots {
          top: calc(100% - 25px);
          width: 290px; } }
      .index-reviews-items .slick-dots > li {
        height: 6px;
        width: 6px;
        marker: none;
        margin-right: 11px;
        display: flex; }
        .index-reviews-items .slick-dots > li:first-child:last-child {
          display: none; }
        .index-reviews-items .slick-dots > li > button {
          width: 100%;
          height: 100%;
          border: none;
          appearance: none;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.29);
          font-size: 0; }
        .index-reviews-items .slick-dots > li.slick-active > button {
          background: #fff; }
  .index-reviews__title {
    font-weight: 900;
    font-size: calc(20px + (100vw - 320px) * 0.00937);
    line-height: calc(26px + (100vw - 320px) * 0.00875);
    text-align: center;
    color: #fff; }
  .index-reviews__img {
    position: absolute;
    left: 0;
    z-index: -1;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.index-seo {
  display: flex;
  flex-direction: column;
  padding-bottom: calc(40px + (100vw - 320px) * 0.0375);
  align-items: center; }
  .index-seo.blog-hidden {
    margin-top: calc(40px + (100vw - 320px) * 0.025); }
  .index-seo-wrapper {
    width: 1500px;
    margin-top: 40px;
    padding: 0 15px;
    display: flex; }
    @media (max-width: 1600px) {
      .index-seo-wrapper {
        width: 1290px; } }
  @media (max-width: 1600px) and (max-width: 1320px) {
    .index-seo-wrapper {
      width: 100%; } }
    @media (max-width: 1170px) {
      .index-seo-wrapper {
        flex-flow: row wrap; } }
  .index-seo-content {
    margin-left: 40px;
    width: 100%;
    height: calc(300px + (100vw - 1170px) * 0.08); }
    .index-seo-content-text {
      padding-right: 31px; }
    @media (max-width: 1170px) {
      .index-seo-content {
        margin-left: 0;
        margin-top: 20px; } }
  .index-seo__image {
    border-radius: 3px;
    object-fit: cover;
    width: calc(422.90625px + (100vw - 1170px) * 0.36146);
    min-width: calc(422.90625px + (100vw - 1170px) * 0.36146);
    height: calc(219.375px + (100vw - 1170px) * 0.1875); }
    @media (max-width: 1170px) {
      .index-seo__image {
        width: 100%;
        height: 300px; } }
  .index-seo__title {
    font-weight: 900;
    font-size: calc(20px + (100vw - 320px) * 0.00937);
    line-height: calc(26px + (100vw - 320px) * 0.0075);
    text-align: center;
    width: 100%;
    color: #333; }

.catalog-select-container {
  width: 1444px;
  margin: 0 auto;
  margin-top: 82px;
  padding: 0 calc(28px + (100vw - 768px) * 0.0408); }
  @media (max-width: 1600px) {
    .catalog-select-container {
      width: 1320px; } }
  @media (max-width: 1600px) and (max-width: 1320px) {
    .catalog-select-container {
      width: 1170px;
      margin-top: 42px; } }
  @media (max-width: 1600px) and (max-width: 1320px) and (max-width: 1170px) {
    .catalog-select-container {
      width: 100%; } }
  .catalog-select-container h2 {
    font-size: calc(28px + (100vw - 768px) * 0.00608);
    text-align: center;
    font-weight: 900;
    margin-bottom: 52px; }
    @media (max-width: 1400px) {
      .catalog-select-container h2 {
        margin-bottom: 32px; } }
  .catalog-select-container .catalog-select-items {
    display: grid;
    grid-template-columns: repeat(6, auto);
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 0; }
    @media (max-width: 1400px) {
      .catalog-select-container .catalog-select-items {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        column-gap: calc(6px + (100vw - 320px) * 0.00893);
        row-gap: calc(6px + (100vw - 320px) * 0.00893); } }
    .catalog-select-container .catalog-select-items .catalog-select-mark {
      width: 100%;
      margin: 0; }
