

$blk: #333;
$red: #ED1847;
$blu: #0054A6;
$b-blu: #00407F;
$borderAD: #ADADAD;
$borderCD: #CDCDCD;
$borderED: #EDEDED;
$breadcrumbsBefore: #9AA7BC;
$disabled: #BCBCBC;
$disabled1: #94B7DA;