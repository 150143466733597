.form-group {
  width: 100%;
  margin-top: calc_fluid(10,13,320,1920);
  &__textarea{
    resize: vertical; 
    height:auto; 
    padding-top:10px; 
    min-height: 110px; 
    max-height: 350px;
  }
  &__input {
    font-size: calc_fluid(12,16,320,1920);
    line-height: calc_fluid(22,24,320,1920);
    color: $blk;
    padding-left: 20px;
    height: calc_fluid(40,53,320,1920);
    width: 100%;
    border: 1px solid $borderCD;
    border-radius: 3px;
    background: #fff;
    &::placeholder {
      color: #878787;
      font-size: calc_fluid(12,16,320,1920);
      line-height: calc_fluid(22,24,320,1920);
    }
  }
  &:first-child {
    margin-top: 0;
  }
}