.departments {
  padding: calc_fluid(60,80,320,1920) 0 calc_fluid_static(60,80,320,1920,-20);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  &-line {
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    overflow: hidden;
    border-top: 1px dashed #878787;
    @include _1600 {
      top: 43px;
    }
    &-inner {
      position: relative;
    }
    &__svg {
      position: absolute;
      left: -5%;
      fill: none;
      height: 34.5px;
      transform: rotate(180deg);
      transition: left 4s ease-in-out;
      width: 34.5px;
      top: -19px;
      @include _1600 {
        right: 0;
        left: 125px;
        @include _600 {
          left: 30px;
        }
      }
    }
  }
  &-map {
    margin-top: 45px;
    position: relative;
    height: 871px;
    width: 100%;
    @include _1170 {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 25px;
      height: unset;
    }
    &-block {
      width: 100%;
      height: calc_fluid(364,712,320,1920);
      min-height: calc_fluid(364,712,320,1920);
    }
    &-interface {
      position: absolute;
      @include _1170 {
        position: unset;
      }
      left: 0;
      width: 100%;
      height: calc(100% - 30px);
      top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 1469px;
        @include _1700 {
          width: 1115px;
          @include _1170 {
            width: 100%;
            padding: 0 15px;
            margin-top: 20px;
          }
        }
      }
      &-form {
        padding: calc_fluid(30,43,320,1920) calc_fluid(24,30,320,1920);
        height: fit-content;
        width: 484px;
        @include _1170 {
          width: 100%;
        }
        z-index: 1;
        background: #fff;
        box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        &-fields {
          margin-top: calc_fluid(16,30,320,1920);
          @include _1170 {
            display: flex;
            justify-content: space-between;
            & > .form-group {
              width: calc(50% - 8px);
              margin-top: 0;
            }
            @include _480 {
              display: block;
              & > .form-group {
                width: 100%;
                margin-top: 10px;
              }
            }
          }
        }
        &-personal, &-personal__link {
          font-size: calc_fluid(12,15,320,1920);
          line-height: calc_fluid(17,21,320,1920);
        }
        &-personal__link {
          text-decoration: underline;
        }
        &-personal {
          color: #A3A3A3;
          margin-top: calc_fluid(8,13,320,1920);
          &__link {
            color: #A3A3A3;
            &:hover {
              color: $red;
            }
          }
        }
        &__btn {
          margin-top: calc_fluid(16,19,320,1920);
          height: calc_fluid(40,53,320,1920);
          width: 100%;
          font-weight: 900;
          font-size: calc_fluid(11,16,320,1920);
          line-height: calc_fluid(15,24,320,1920);
        }
        &__title {
          font-size: calc_fluid(18,28,320,1920);
          font-weight: 900;
          line-height: calc_fluid(22,36,320,1920);
          text-align: center;
        }
        &__description {
          margin-top: calc_fluid(12,7,320,1920);
          font-size: calc_fluid(14,16,320,1920);
          line-height: calc_fluid(21,24,320,1920);
          color: #6D6D6D;
          text-align: center;
        }
      }
      &-slider {
        $_: &;
        height: 338px;
        width: 1622px;

        &.centres-edits {
          width: 1270px;
          @include _1170 {
            width: 100%;
          }
          @media (min-width: 970px) {
            align-items: center;
            justify-content: center;

            position: absolute;
            top: -25%;
            left: 0;
            right: 0;

            margin: auto;
            #{$_}-slides {
              margin: 0;
            }
          }
        }

        @include _1700 {
          width: 100%;
          @include _1320 {
            width: 1140px;
            @include _1170 {
              width: 100%;
              height: 284px;
              margin-top: 20px;
            }
          }
        }
        display: flex;
        justify-content: center;
        z-index: 1;
        position: relative;
        &-slides {
          max-width: 1130px;
          margin-right: 100px;
          @include _1700 {
            margin-right: 0;
            max-width: 1115px;
            @include _1170 {
              max-width: 925px;
              @include _979 {
                max-width: 100%;
              }
            }
          }
          display: flex;
          overflow: hidden;
          & .slick-list {
            width: 100%;
          }
          & .slick-track {
            display: flex;
          }
        }
        &-slide {
          $ds: &;
          padding: 0 10px;
          @include _600 {
            padding: 0 15px;
            max-width: 310px;
          }
          &-inner {
            position: relative;
            border: 1px solid $blu;
            border-radius: 3px;
            background: #fff;
            width: 352px;
            transition: box-shadow .3s, border .3s;
            height: 318px;
            @include _1170 {
              width: 290px;
              @include _480 {
                width: 100%;
              }
              height: 264px;
            }
            padding: calc_fluid(15, 30, 320,1920) calc_fluid(15, 28, 320,1920) calc_fluid(24, 30, 320,1920);
          }
          &-geo {
            position: absolute;
            top: 0;
            right: 0;
            height: calc_fluid(35.82,58,320,1920);
            width: calc_fluid(35.51,55.5,320,1920);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &__icon {
              height: calc_fluid(14,21,320,1920);
              width: calc_fluid(14,21,320,1920);
              fill: $blk;
            }
            $isg:&;
            &:hover {
              #{$isg} {
                &__icon {
                  fill: $red;
                }
              }
            }
          }
          &-bottom {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            &__ph {
              width: calc_fluid(173,185,320,1920);
              height: calc_fluid(58,67,320,1920);
              object-fit: cover;
            }
            @include _1170 {
              margin-top: 20px;

            }
          }
          &-btn {
            width: calc_fluid(62,89,320,1920);
            height: calc_fluid(58,67,320,1920);
            background: $blu;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            &__icon {
              height: 33px;
              width: 33px;
              fill: #fff;
            }
          }
          &__obl {
            font-size: 15px;
            line-height: 20px;
            @include _1170 {
              font-size: 13px;
            }
            color: #878787;
          }
          &__title {
            display: inline-block;
            
            margin-top: 2px;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding-right: 30px;

            
            @include _1170 {
              margin-top: 3px;
              font-size: 14px;
              line-height: 20px;
            }
            color: #333333;
          }
          &__phone {
            margin-top: 10px;
            padding: 4px 0;
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #0054A6;
            text-decoration-line: underline;
            @include _1170 {
              font-size: 14px;
              line-height: 20px;
              margin-top: 4px;
            }
            &:hover {
              color: $red;
            }
          }
          &__time {
            margin-top: 12px;
            max-width: 194px;
            font-size: 16px;
            line-height: 24px;
            color: $blk;
            @include _1170 {
              margin-top: 8px;
              font-size: 14px;
              line-height: 20px;
            }
          }
          &.slick-current {
            #{$ds} {
              &-inner {
                box-shadow: 0 7px 4px rgba(0, 0, 0, 0.15);
                border: transparent;
              }
              &-geo {
                background: $red;
                border-radius: 0 0 0 3px;
                &__icon {
                  fill: #fff !important;
                }
              }
            }

          }
        }
        &-arrow {
          &s {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            height: 100%;

            padding: 0 10px;
          }
          &.slick-hidden {
            display: none;
          }
          width: calc_fluid(24.75,48,320,1920);
          height: calc_fluid(24.75,48,320,1920);
          bottom: 20px;
          cursor: pointer;
          transform: rotate(-45deg);
          $dmisa: &;
          background: $blu;
          z-index: 1;
          box-shadow: 11px 12px 30px rgba(14, 16, 30, 0.08);
          transition: box-shadow .3s, background-color .3s;
          border: none;
          appearance: none;
          display: flex;
          align-items: center;
          justify-content: center;
          &.prev {
            transform: rotate(135deg);
          }
          &__icon {
            width: calc_fluid(15.47,30,320,1920);
            height: calc_fluid(15.47,30,320,1920);
            transform: rotate(-45deg);
            fill: #fff;
            transition: fill .3s;
          }
          &:hover {
            box-shadow: none;
            background: $red;
            #{$dmisa} {
              &__icon {
                fill: #fff;
              }
            }
          }
        }
      }
      @include _1170 {
        flex-direction: column-reverse;
      }
    }
  }
  &__title {
    font-weight: 900;
    font-size: calc_fluid(28,35,768,1920);
    padding: 0 15px;
    color: $blk;
    position: relative;
    z-index: 2;
    text-align: center;
    background: #fff;
    line-height: calc_fluid(36,38,768,1920);
    @include _768 {
      max-width: 640px;
      font-size: calc_fluid(20,28,320,768);
      line-height: calc_fluid(26,36,320,768);
    }
    & > b {
      color: $blu;
    }
  }
  &__description {
    margin-top: calc_fluid(16,20,320,1920);
    padding: 0 15px;
    font-size: calc_fluid(16,17,768,1920);
    line-height: calc_fluid(24,26,768,1920);
    @include _768 {
      font-size: calc_fluid(14,16,320,768);
      line-height: calc_fluid(21,24,320,768);
    }
    text-align: center;
    color: #878787;
  }
}