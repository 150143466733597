@mixin _1920 {
   @media (max-width: 1920px) {
    @content;
  }
}

@mixin _1700 {
  @media (max-width: 1700px) {
    @content;
  }
}

@mixin _1800 {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin _1600 {
   @media (max-width: 1600px) {
    @content;
  }
}

@mixin _1320 {
  @media (max-width: 1320px) {
    @content;
  }
}

@mixin _1290 {
  @media (max-width: 1290px) {
    @content;
  }
}

@mixin _1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin _1170 {
  @media (max-width: 1170px) {
    @content;
  }
}

@mixin _992 {
  @media (max-width: 979px) {
    @content;
  }
}

@mixin _979 {
  @media (max-width: 979px) {
    @content;
  }
}

@mixin _960 {
  @media (max-width: 979px) {
    @content;
  }
}

@mixin _920 {
  @media (max-width: 929px) {
    @content;
  }
}

@mixin _860 {
  @media (max-width: 860px) {
    @content;
  }
}

@mixin _768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin _660 {
  @media (max-width: 660px) {
    @content;
  }
}


@mixin _600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin h600 {
  @media (max-height: 600px) {
    @content;
  }
}

@mixin _566 {
  @media (max-width: 566px) {
    @content;
  }
}

@mixin _480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin _350 {
  @media (max-width: 350px) {
    @content;
  }
}

@mixin _320 {
  @media (max-width: 320px) {
    @content;
  }
}
